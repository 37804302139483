* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// FONTS

// COLORS
$white: #FFFFFF;
$gray: #72797A;
$primary: #455252;
$secondary: #0C4241;
$manrope: 'Manrope', sans-serif;
html {
    scroll-behavior: smooth;
}


body {
    font-family: $manrope;
    font-weight: 400;
    color: $primary;
    background-color: $white;
    min-height: 100vh;
}

.cmndv{
    background: url('../assets/images/cmndvbg.png') no-repeat;
    background-size: cover;
}

.container{    
    // @media(min-width: 1250px){
    //     max-width: 1221px;
    // }
    @media(min-width: 1200px){
        max-width: 1450px;
    }
}

@media (min-width: 1200px){
    .container-1180{
        max-width: 1180px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

ul{
    padding-left: 0;
}

ul li{
    list-style: none;
}

// ------------------------COMMON------------CSS-----/////

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
    text-decoration: none !important;
    cursor: pointer;
}

.flex-d {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.display-hidden {
    visibility: hidden;
}

.primary-btn {
    background: $secondary;
    box-shadow: 2px 12px 50px rgba(4, 21, 21, 0.43);
    border-radius: 40px;
    min-width: 190px;
    min-height: 53px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #F3FFFF;
    font-weight: 600;
    font-size: 16px;

    &:active, &:hover, &:focus {
        background: #258886;
        color: #FFFFFF;
        box-shadow: none;
        transition: all .5s;
    }
    @media(max-width:575px){
        min-width: 136px;
        min-height: 43px;
        font-size: 14px;
    }
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:focus{
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

// ______________________CHECKBOX__________________//@at-root
/* Customize the label (the container) */
.chkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: $gray;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media(max-width: 575px){
        font-size: 12px;
    }
  }
  
  /* Hide the browser's default checkbox */
  .chkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 18px;
    width: 18px;
    background: #358882;
    border-radius: 6px;
    border: 1px solid #C2CACA;
  }
  
  /* On mouse-over, add a grey background color */
  .chkcontainer:hover input ~ .checkmark {
    background-color: #258886;
  }
  
  /* When the checkbox is checked, add a blue background */
  .chkcontainer input:checked ~ .checkmark {
    background-color: #258886;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .chkcontainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .chkcontainer .checkmark:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(37deg);
  }

  // ______________________RADIO__________________//@at-root
/* Customize the label (the container) */
.chkcontainer1 {
    display: block;
    position: relative;
    // padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: $gray;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 35px;
    @media(max-width: 575px){
        font-size: 12px;
        padding: 0 25px;
        &:last-child{
            padding-right: 15px;
        }
    }
  }
  
  /* Hide the browser's default checkbox */
  .chkcontainer1 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark1 {
    position: absolute;
    top: 14px;
    left: 1px;
    height: 18px;
    width: 18px;
    // background: #358882;
    border-radius: 50PX;
    border: 2px solid #258886;
    @media(max-width:575px){
        top:6px;
        width: 15px;
        height: 15px;
        left: 5px;
    }
  }
  
  /* On mouse-over, add a grey background color */
//   .chkcontainer1:hover input ~ .checkmark1 {
//     background-color: #258886;
//   }
  
  /* When the checkbox is checked, add a blue background */
//   .chkcontainer1 input:checked ~ .checkmark1 {
//     background-color: #258886;
//   }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark1:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .chkcontainer1 input:checked ~ .checkmark1:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .chkcontainer1 .checkmark1:after {
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background: #258886;
    border-radius: 50px;
    @media(max-width:575px){
        left: 2px;
        top: 2px;
        width: 7px;
        height: 7px;
    }
  }

// ------------------------COMMON------------CSS---ENDS--------------//


// _________________ANIMATION ______________________//

.rotate {
    animation: rotate 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.focus-in-expand {
    animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes focus-in-expand {
    0% {
        letter-spacing: 1em;
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

.focus-rotate {
    animation: focus-rotate 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes focus-rotate {
    0% {
        transform: rotate(0deg);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        transform: rotate(180deg);
        filter: blur(0px);
        opacity: 1;
    }
}

.focus-roa {
    animation: focus-roa 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes focus-roa {
    0% {
        transform: rotate(180deg);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        transform: rotate(0deg);
        filter: blur(0px);
        opacity: 1;
    }
}

.fo-oa {
    animation: fo-oa 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both infinite;
}

@keyframes fo-oa {
    0% {
        fill: #7aabc1;
    }


    100% {
        fill: $primary;
    }
}

.path-start {
    stroke-dasharray: 100;
    animation: dash 5s linear;
}

@keyframes dash {
    to {
        stroke-dashoffset: 1000;
    }
}

.path-reverse {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

// ______________________ANIMATION____________ENDS________________//


// ---------------SCROLL____________BAR-------------//
// SCROLL BAR
::-webkit-scrollbar {
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

//________________ SCROLL_________BAR____________ENDS//





// .cal {
//     min-height: calc(100% - 50px);
// }

.check-form {
    @extend .flex-d;
    justify-content: start;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background: #ECECEC;
        ;
        border: 1px solid rgba(236, 236, 236, .2);
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: '';
        width: 8px;
        height: 8px;
        background: #81729C;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


.cntldvinr{
    min-height: calc(100vh - 592px);
    padding-top:115px;
}

/* Header CSS Start */
header{
    z-index: 99;
    position: relative;    
    .bg-dark{
        background-color: transparent !important;
        .navbar-nav{
            .nav-item{
                &:last-child{
                    .nav-link{
                        padding-right: 0;
                    }
                }
                .nav-link{
                    font-weight: 500;
                    font-size: 16px;
                    color:$secondary;
                    letter-spacing: -0.185px;
                    // padding: 0 1rem;
                    padding: 0 45px 0 0px;                    
                    // @media(min-width:992px) and (max-width:1199px){
                    //     font-size: 14px;
                    //     padding: 0 0.75rem;
                    // }
                    @media(max-width:1199px){
                        padding: 0.75rem 0;
                    }
                    &:hover{
                        color:#258886;
                        transition: all .5s;
                    }
                }
                .dropdown{
                    button{
                        font-weight: 500;
                        font-size: 16px;
                        color:$secondary;
                        // padding: 0 1rem;
                        padding: 0 45px 0 0px;
                        // @media(min-width:992px) and (max-width:1199px){
                        //     font-size: 14px;
                        //     padding: 0 0.75rem;
                        // }
                        @media(max-width:1199px){
                            padding: 0.75rem 0;
                        }
                        &:hover{
                            color:#258886;
                            transition: all .5s;
                            img{
                                filter: invert(52%) sepia(7%) saturate(2994%) hue-rotate(130deg) brightness(85%) contrast(102%);
                            }
                        }
                    }
                    .dropdown-menu{
                        padding: 0;
                        background: $secondary;
                        button{
                            padding: 0.75rem 1rem;
                            color: #F3FFFF;                           
                            &:hover{
                                background:#258886;
                                transition: all .5s;
                            }
                        }
                    }
                }
            }           
        }
        .navbar-toggler{
            display: none !important;
            @media(max-width:1199px){
                background: linear-gradient(180deg, rgba(150, 199, 214, 0.1) 0%, rgba(200, 240, 252, 0.06) 100%);
                border: 1px solid rgba(37, 136, 134, 0.15);
                box-shadow: -9px 8px 25px rgba(0, 0, 0, 0.13);
                backdrop-filter: blur(10px);
                width: 50px;
                height: 45px;
                border-radius: 10px;
                display: flex !important;
                justify-content: center;
                align-items: center;
                .navbar-toggler-icon{
                    background: url('../assets/images/menu.png') no-repeat;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        @media(max-width:1199px){
            background: rgba(248, 249, 249, 0.05);
            backdrop-filter: blur(12px);
           
        }         
    }
    .bg-dark.scrolled{
        background: rgba(248, 249, 249, 0.5);
        backdrop-filter: blur(5px);
        background: rgba(221, 250, 231, 0.5) !important;        
    }
    .lgcndv{
        @extend .flex-a;
        .dropdown{
            button{
                font-weight: 500;
                font-size: 16px;
                color:$secondary;
                padding: 0 15px;
                @media(max-width:1199px){
                    font-size: 14px;
                    padding: 0 1rem;
                }
                &:hover{
                    color:#258886;
                    transition: all .5s;
                    img.dnarr{
                        filter: invert(52%) sepia(7%) saturate(2994%) hue-rotate(130deg) brightness(85%) contrast(102%);
                    }
                }
                span{
                    margin-left: 12px;
                    margin-right: 6px;
                }
                img.dnarr{
                    width: 10px;
                    height: 6px;
                }
            }
            .dropdown-menu{
                padding: 0;
                background: $secondary;
                button{
                    padding: 0.75rem 1rem;
                    color: #F3FFFF;
                    &:hover{
                        background:#258886;
                        transition: all .5s;
                    }
                }
            }            
        }
        @media(max-width:1199px){
            display: block;
            .dropdown{
                margin-right: 0;
                button.btn-secondary{
                    padding: 0;
                }
            }
        }
        @media(max-width:1199px){
            .primary-btn{
                margin:1rem 0;
            }
        }
    }
    .wldv{
        display: none;        
        @media(max-width: 1199px){
            display: block;
            background: linear-gradient(180deg, rgba(150, 199, 214, 0.1) 0%, rgba(200, 240, 252, 0.06) 100%);
            border: 1px solid rgba(37, 136, 134, 0.15);
            box-shadow: -9px 8px 25px rgba(0, 0, 0, 0.13);
            backdrop-filter: blur(10px);
            width: 50px;
            height: 45px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

/* Header CSS End */

/* Home CSS Start */
// .bnrbgtp{
//     background: url('../assets/images/banner-bg.png') no-repeat;
//     background-size: contain;
// }
.bnrsec{
    background: url('../assets/images/banner-bg.png') no-repeat;
    background-size: cover;
    margin-top: -145px;
    position: relative;
    padding-bottom: 204px;
    z-index: 92;
    @media(max-width:575px){
        margin-top: 0px;
        padding-top: 15px;
        padding-bottom: 115px;
    }
    .bnrimg{
        padding-top: 250px;
        @media(max-width:575px){            
            padding-top:90px;
        }
        .brnmbimg{
            display: none !important;
            @media(max-width: 575px){
                display: block !important;
            }
        }
        // @media(max-width: 575px){
        //     .brndtimg{
        //         display: none !important;
        //     }
        // }
    }
    .bncnt{
        position: absolute;
        top:40%;
        @media(max-width:575px){
            top:45%;
        }
        h1{
            font-weight: 700;
            font-size: 96px;
            color: #455252;
            margin-bottom: 25px;
            @media(min-width: 1200px) and (max-width: 1449px){
                font-size: 75px;
            }
            @media(min-width: 992px) and (max-width: 1199px){
                font-size: 85px;
            }
            @media(min-width: 768px) and (max-width: 991px){
                font-size: 65px;
            }
            @media(min-width: 576px) and (max-width: 767px){
                font-size: 50px;
            }
            @media(max-width: 575px){
                font-size: 20px;
            }
        }    
        .bnrsbcnt{
            position: relative;           
            p{
                font-weight: 500;
                font-size: 24px;
                color: #455252;
                margin-left: 2.5rem;
                margin-bottom: 50px;
                @media(min-width: 768px) and (max-width: 991px){
                    font-size: 20px;
                }
                @media(min-width: 576px) and (max-width: 767px){
                    font-size: 18px;
                }
                @media(max-width: 575px){
                    font-size: 14px;
                    margin-left: 1.5rem;
                }
            }
            h3{
                font-weight: 700;
                font-size: 48px;
                color: #0C4241;
                margin-left: 2.5rem;
                margin-bottom: 25px;
                @media(min-width: 768px) and (max-width: 991px){
                    font-size: 40px;
                }
                @media(min-width: 576px) and (max-width: 767px){
                    font-size: 35px;
                }
                @media(max-width: 575px){
                    font-size: 16px;
                    margin-bottom: 20px;
                    margin-left: 1.5rem;
                }
            }
            &:after{
                content: '';
                background: #223E3D;
                width: 4px;
                height: 100%;
                position: absolute;
                top:5px;
                left: 5px;
                @media(max-width: 575px){
                    width: 1px;
                }
            }
        }
        @media(max-width: 575px){
           .primary-btn{
                margin-left: 0 !important;
           }
        }
    }
    .socdv{
        display: flex;
        justify-content: flex-end;
        align-items: end;
        flex-direction: column;
        position: absolute;
        right: 0;
        bottom: 0;
        @media(min-width:1200px) and (max-width:1449px){
            top:0;
        }
        @media(min-width: 576px) and (max-width: 767px){
            bottom: 25px;
        }
        @media(max-width:575px){
            top: 65px;
        }
        h4{
            font-weight: 600;
            font-size: 32px;
            color: #0C4241;
            margin-bottom: 16px;
            @media(min-width: 576px) and (max-width: 767px){
                font-size: 20px;
            }
            @media(max-width:575px){
                font-size: 16px;
                margin-bottom: 15px;
            }
        }
        .socdvdt{
            display: flex;
            margin-right: 80px;
            @media(min-width: 576px) and (max-width: 767px){
                margin-right: 15px;
            }
            @media(max-width:575px){
                margin-right: 20px;
            }
        }
        .socdvdtcnt{
            width: 50px;
            height: 50px;
            background: #abc7f8;
            border-radius: 15px;
            border: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 21px;
            cursor: pointer;            
            &:last-child{
                margin-right:0;
            }
            &:hover{
                background: #afe0fd;
                transition: all .5s;
                img{
                    transform: rotate(45deg);
                    transition: all .5s;
                }
            }
            @media(max-width:575px){
               width:40px;
               height:40px;
               img{
                width: 24px;
               }
            }
        }
    }
}
.trdbuybg{
    background: url('../assets/images/trdernbg.png') no-repeat center;
    background-size: 100% 100%;
    padding-top: 250px;
    max-height: 761px;
    position: relative;
    bottom: 217px;
    z-index: 9;
    @media (min-width:992px) and (max-width:1199px){
        max-height: 630px;
        bottom: 180px;
    }
    @media (min-width:768px) and (max-width:991px){
        max-height: 529px;
        bottom: 200px;
        padding-top: 200px;
    }
    @media (min-width:576px) and (max-width:767px){
        padding-top: 155px;
        max-height: 400px;
        bottom: 114px;
    }
    @media(max-width:575px){
        padding-top: 94px;
        max-height: 250px;
        bottom: 72px;
    }
}
.trdernsec{
    // background: url('../assets/images/trdernbg.png') no-repeat center;
    // background-size: cover;
    // padding: 1rem 0;
    // margin-top: 45px;
    img{
        display: flex;
        justify-content: flex-end;
        @media(min-width:992px) and (max-width: 1199px){
            max-width: 850px;
        }
        @media(min-width:768px) and (max-width: 991px){
            max-width: 750px;
        }
        @media(min-width:575px) and (max-width: 767px){
            max-width: 500px;
        }
        @media(max-width:576px){
            max-width: 326px;
        }
    }
//   
    // .trdernwntxt{
    //     h1{
    //         font-weight: 800;
    //         font-size: 130px;
    //         color: $secondary;
    //         letter-spacing: 0.062em;
    //         -webkit-text-stroke: 1px $secondary;
    //         -webkit-text-fill-color: transparent;
    //         span{
    //             margin: 0 105px;
    //         }
    //         @media(min-width:1200px) and (max-width: 1375px){
    //             font-size: 116px;
    //         }
    //         @media(min-width:992px) and (max-width: 1199px){
    //             font-size: 80px;
    //             margin-top: 0.5rem;
    //         }
    //         @media(min-width:768px) and (max-width: 991px){
    //             font-size: 65px;
    //             margin-top: 0.5rem;
    //         }
    //         @media(min-width:576px) and (max-width: 767px){
    //             font-size: 50px;
    //             margin-top: 0.5rem;
    //         }
    //         @media(max-width:576px){
    //             font-size: 32px;
    //             margin-top: 0.5rem;
    //         }
    //     }
    // }
    h2{
        font-weight: 800;
        font-size: 140px;
        letter-spacing: 0.062em;
        text-transform: uppercase;
        color: $secondary;
        margin-bottom: 0;
        span{
            margin: 0 105px;
            @media(max-width: 575px){
                margin:  0 25px;
            }
        }        
        @media(min-width:1200px) and (max-width: 1375px){
            font-size: 116px;
        }
        @media(min-width:992px) and (max-width: 1199px){
            font-size: 80px;
            margin-top: 0.5rem;
        }
        @media(min-width:768px) and (max-width: 991px){
            font-size: 65px;
            margin-top: 0.5rem;
        }
        @media(min-width:576px) and (max-width: 767px){
            font-size: 50px;
            margin-top: 0.5rem;
        }
        @media(max-width:576px){
            font-size: 32px;
            margin-top: 0.5rem;
        }
    }
}
.buytknsec{
    // padding:4rem 0;
    padding: 203px 0 200px 0;
    @media(min-width:1200px) and (max-width:1375px){
        padding: 6rem 0;
    }
    @media(max-width:575px){
        padding: 20px 0 0;
    }
    .slick-dots{
        bottom:-35px
    }
    .slick-slider{
        @media(max-width: 991px){
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    .slick-dots li button::before{
        font-size: 15px;
        color: #BDDCDA;
        background: #E8F0F0;
        // background: green;
        border-radius: 50%;
        box-shadow: 0px 4px 54px rgba(53, 136, 129, 0.18);
        line-height: 22px;
        opacity: 1;
    }
    .slick-dots li.slick-active button::before{
        color: #258886;
    }
    .slick-list .slick-track .slick-slide img{
        width: auto !important;
        display: block !important;
        margin: auto;
    }
    .slick-dots li:after{
        content: '';
        position: absolute;
        background: #E8F0F0;
        box-shadow: 0px 4px 54px rgba(53, 136, 129, 0.18);
        width: 100%;
        transform: rotate(180deg);
        height: 24%;
        left: 19px;
        bottom: 8.5px;
    }
    .slick-dots li:last-child:after{
        width: 0;
    }
    .buytkninrsec{
        border: 1px solid #DAE1E0;
        box-shadow: 1px -3px 75px rgba(171, 156, 208, 0.12);
        border-radius: 26px;
        padding: 80px 0;
        @media(max-width:767px){
            padding: 40px 13px;
        }
    }
    h3{
        font-weight: 800;
        font-size: 50px;
        color: $secondary;
        line-height: 1.35;
        text-align: center;
        display: block;
        margin: auto auto 34px;
        max-width: 1067px;
        @media(min-width: 992px) and (max-width: 1199px){
            font-size: 35px;
        }
        @media(min-width: 576px) and (max-width: 767px){
            font-size: 35px;
        }
        @media(max-width: 575px){
            font-size: 24px;
            line-height: 1.5;
            margin-top: 30px;
        }
    }
    p{
        font-weight: 600;
        font-size: 18px;
        color: $primary;
        margin-bottom: 32px;
        line-height: 1.75;
        display: block;
        margin: auto auto 32px;
        max-width: 770px;
        text-align: center;
        @media(min-width: 992px) and (max-width: 1199px){
            font-size: 16px;
        }
        @media(min-width: 576px) and (max-width: 767px){
            font-size: 16px;
        }
        @media(max-width: 575px){
            font-size: 14px;
        }
        span{
            color: $secondary;
        }
    }
    .mltvdacc{
        h4{
            font-weight: 600;
            font-size: 24px;
            color: $secondary;
            margin-bottom: 1rem;
            @media(min-width: 992px) and (max-width: 1199px){
                font-size: 20px;
            }
            @media(min-width: 576px) and (max-width: 767px){
                font-size: 20px;
            }
            @media(max-width: 575px){
                font-size: 16px;
            }
        }
        .mltcdaccdt{
            display: flex;
            margin-bottom: 1.5rem;
            .mltcdaccdtcnt{
                background: linear-gradient(180deg, #D9DEF2 0%, #ECF3FA 53.28%, #D9DEF2 100%);
                border-radius: 8px;
                width: 106px;
                height: 52px;
                @extend .flex-a;
                margin-right: 20px;
            }
            
        }
    }
    .trmdv{
        margin-bottom: 15px;
        h5{
            font-weight: 600;
            font-size: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            color: #258886;
            padding: 0 60px;
            img{
                margin-right: 6px;                
            }
            // .trdrmdtgmob{
            //     display: none;
            // }
            // @media(max-width:575px)                {
            //     .trdrmdtg{
            //         display: none;
            //     }
            //     .trdrmdtgmob{
            //         display: block;
            //     }
            // }
            @media(min-width: 992px) and (max-width: 1199px){
                font-size: 14px;
            }
            @media(min-width: 768px) and (max-width: 991px){
                font-size: 14px;
                padding: 0 30px;
            }
            @media(max-width: 767px){
                font-size: 10px;
                width: 50%;
                padding: 0;
            }
        }
    }

}
.sersec{
    margin-top: 40rem;    
    position: relative;
    padding-bottom: 100px;
    .serseccol{
        display: flex;
        align-items: flex-end;
        justify-content: center;
       @media(max-width:991px){
        margin-bottom: 6rem;        
       }
       @media (min-width:768px) and (max-width: 991px){
            &:nth-child(5), &:nth-child(6){
                margin-bottom: 0;
            }
       }
       @media(max-width:575px){
        margin-bottom: 1rem;
       }
    }
    &:after{
        content:'';
        position: absolute;
        background: url('../assets/images/servbg.png') no-repeat;
        top: -50rem;
        right: 0;
        width: 1021px;
        height: 1357px;
        @media(max-width: 767px){
            background: url('../assets/images/servbgmob.png') no-repeat;
            width: 191px;
            height: 236px;
            top: -75px;
            right: 0;
        }
    }
    @media (min-width:768px) and (max-width:991px){
        margin-top: 35rem;
    }
    @media (min-width:576px) and (max-width:767px){
        margin-top: 44rem;
    }
    @media(max-width:575px){
        margin-top:30rem !important;
        padding-bottom: 0 !important;
    }
    @media(max-width:420px){
        margin-top: 37rem !important;
    }
    .servseccntg{
        @media(max-width:575px){
            max-width: 185px;
        }
    }
    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 61px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        // color: white;
        // text-shadow: -1px -1px 0 #0c4241, 1px -1px 0 #0c4241, -1px 1px 0 #0c4241, 1px 1px 0 #0c4241;        
        text-align: center;
        margin-bottom: 0;
        position: relative;
        -webkit-text-stroke: 2px #0c4241;
        &:after{
            content: attr(data-text);
            position: absolute;
            left: 50%;
            -webkit-text-stroke: 0;
            color: white;
            pointer-events: none;
            transform: translateX(-50%);
            width: 100%;
        }
        @media(max-width:991px){
            font-size: 35px;
        }
        @media(max-width:575px){
            font-size: 24px;
        }
    }
    h3{
        font-weight: 800;
        font-size: 50px;
        color: $secondary;
        text-align: center;
        margin-bottom: 150px;
        @media(max-width: 575px){
            margin-bottom: 7rem;
            font-size: 24px;
        }
    }
    .OurSerHt{
        margin-bottom: 6rem;
        @media(max-width: 575px){
            margin-bottom: 1rem;
        }
    }
    .servsecdtcnt{
        background: linear-gradient(180deg, #E7F6FF 0%, #FAF8FF 100%);
        border: 2px solid #FFFFFF;
        box-shadow: 0px 4px 39px rgba(171, 156, 208, 0.25);
        padding: 4rem 1.5rem;
        // margin-bottom: 6rem;
        border-radius: 25px;
        position: relative;
        height: 100%;
        min-height: 265px;
        cursor: pointer;
        z-index: 3;
        &:hover{
            background: #E7F6FF;
            transition: all .5s;
        }
        @media (min-width:992px) and (max-width:1199px){
            min-height: 295px;
        }
        @media (max-width:575px){
            min-height: 207px;
            margin-bottom: 7rem; 
            height: auto;           
        }
        @media (max-width:420px){
            max-width: 333px;
        }
        h5{
            font-weight: 700;
            font-size: 26px;
            color: $secondary;
            margin-bottom: 1.25rem;
            @media (min-width:768px) and (max-width:1199px){
                font-size: 23px;
            }
            @media (max-width:575px){
                font-size: 16px;
                font-weight: 800;
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            color: $gray;
            margin-bottom: 0;
            @media (min-width:768px) and (max-width:1199px){
                font-size: 15px;
            }
            @media (max-width:575px){
                font-size: 14px;
            }
            span{
                color: #455252;
            }
        }
        .servsecdtcntimgdv{
            background: linear-gradient(222.49deg, #E9F7FF -3.08%, #F6F8FF 90.82%);
            border: 2px solid #FFFFFF;
            box-shadow: 0px 4px 37px rgba(215, 149, 123, 0.7);
            border-radius: 25px;            
            width: 111px;
            height: 111px;
            position: absolute;
            top: -65px;
            img{
                display: block;
                margin: auto;
                margin-top:12px;
            }
        }
    }
}
.cntdv{
    background: #FFFBF4;
    padding: 67px 2rem;    
    @media(max-width:575px){
        margin-top: -85px;
    }
    @media(max-width:767px){
        padding: 2rem 2rem 1rem;
    }
    @media(max-width:575px){
        padding: 2rem 0 1rem;
    }
    .cntdvp{
        display: flex;
        justify-content: center;
        // justify-content: space-around;
        // align-items: center;
        flex-wrap: wrap;
        .cntdvcnt{
            text-align: center;
            position: relative;
            width:20%;
            @media(max-width:767px){
                width: 33.3%;
                margin-bottom: 1.5rem;
            }            
            &:last-child:after{
                display: none;
            }
            @media(max-width:767px){
                &:nth-child(3):after{
                    display: none;
                }
            }
            &:after{
                content: '';
                position: absolute;
                top: 0;
                background: #C2CACA;
                width: 2px;
                height: 100%;
                right: 0;
                // @media(min-width:768px) and (max-width:1199px){
                //     right: -25%;
                // }
            }
            h6{
                font-weight: 800;
                font-size: 26px;
                color: $secondary;
                @media(max-width:767px){
                    display: none;
                }
                @media(min-width:992px) and (max-width:1199px){
                    font-size: 20px;
                }
                @media(max-width:991px){
                    font-size: 18px;
                }
            }
            .cntdvcntmb{
                display: none;
                @media(max-width: 767px){
                    display: block;
                }
            }
            p{
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 0;
                @media(min-width:992px) and (max-width:1199px){
                    font-size: 16px;
                }
                @media(min-width:576px) and (max-width:991px){
                    font-size: 14px;
                }
                @media(max-width: 575px){
                    font-size: 12px;
                }
            }
        }
    }
}
/*--||
Flow Chart Content Use Start
||--*/
.flwCnt {
    padding:90px 0px 122px;
    overflow: hidden;
    @media(max-width:575px){
        padding: 45px 0px 60px;
    }
    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 61px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        // color: white;
        // text-shadow: -1px -1px 0 #0c4241, 1px -1px 0 #0c4241, -1px 1px 0 #0c4241, 1px 1px 0 #0c4241;
        margin-bottom: 0;
        position: relative;
        -webkit-text-stroke: 2px #0c4241;
        &:after{
            content: attr(data-text);
            position: absolute;
            left: 0;
            -webkit-text-stroke: 0;
            color: white;
            pointer-events: none;
            width: 100%;
            @media(max-width:575px){
                left:50%;
                transform: translateX(-50%);
            }
        }
        @media(max-width:991px){
            font-size: 35px;
        }
        @media(max-width:575px){
            font-size: 24px;
            text-align: center;
        }
    }
    
}

@media only screen and (min-width: 1200px)
{
    .flwCnt .container {
        max-width: 1450px;
    }
}



.flwR {
    align-items: center;
}
.flwC-lt {
    text-align: right;
}



.flwChStr {
    position: relative;
    text-align: right;
    padding: 0rem 4.625rem 0rem 0rem;
    margin-bottom: 3.5rem;
    display: inline-block;
}

.flwLblStr {
    padding: 12px 0px;
}




.flwChR {
    display: flex;
    justify-content: center;
    margin: 0px -4rem;
}

.flwChC {
    padding: 0px 30px;
    padding: 0px 4rem;
}

.flwChCd {
    background-image: linear-gradient(251.91deg, #DFE5FE 1.05%, #ECF3FA 43.91%, #E3E7FA 98.72%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 39px rgba(171, 156, 208, 0.25);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 9;
}

.flwCh-353212 {
    width: 353px;
    min-height: 212px;
    padding: 1rem;
}

.flwChTx {
    color: #0C4241;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.flwChTx.v2 {
    max-width: 80%;
}

.flwChTx.v3 {
    max-width: 50%;
}

.flwLbl {
    display: inline-block;
    background-image: linear-gradient(180deg, #D9DEF2 0%, #ECF3FA 53.28%, #D9DEF2 100%);
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.09);
    border-radius: 25px;
    color: #455252;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 0.5rem 1rem;
    text-align: center;
}

.flwChCdLbl {
    margin: 1rem 0px 0px;
    overflow: auto;
}

.flwChCdLbl .flwLbl
{
    min-width: 110px;
}

.flwChCdLbl .flwLblCnt
{
    float: left;
    display: inline-block;
}

.flwChCdLbl .flwLblCnt:first-child 
{
    width: 100%;
    text-align: center;
}

.flwChCdLbl .flwLblCnt:nth-child(2)
{
    clear: left;margin: 8px 5px;
}

.flwChCdLbl .flwLblCnt:nth-child(3)
{
    margin: 8px 5px;
}

.flwChCdLbl .flwLblCnt:last-child {
    clear: left;
    width: 100%;
    text-align: center;
}

.flwChCdV2 {
    background-image: linear-gradient(110.46deg, #E0E8FF 3.97%, #C2EDE3 50.55%, #FEDDDD 96.17%);
    box-shadow: -5px 14px 61px rgba(0, 0, 0, 0.19);
    border-radius: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px;
    position: relative;
    z-index: 9;
}

.flwCh-253171 {
    width: 253px;
    min-height: 171px;
}

.flwChCdV2 h2 {
    color: #72797A;
    font-weight: 700;
    font-size: 32px;
    margin: 0px;
}

.flwChCdV2 h6 {
    margin: 0px;
    letter-spacing: 0.19em;
    color: #72797A;
    font-weight: 700;
    font-size: 14px;
}

.flwCh-227154 {
    width: 227px;
    min-height: 154px;
}

.flwChCd-sp {
    margin: 2.25rem 0px;
}

.flwCh-237161 {
    width: 237px;
    min-height: 161px;
}



.flwChCon {
    position: relative;
}

.flwJnIg {
    position: absolute;
}

.flwJnIg-1 {
    top: 50%;
    right: -11%;
    transform: translate(0%, -50%);
}
.flwJnIg-2 {
    right: -24%;
    top: 0%;
    transform: translate(0%, -50%);
}
.flwJnIg-3 {
    left: -50%;
    top: 43%;
    transform: translate(0px, 0%);
}

.flwJnIg-4 {
    right: -46%;
    bottom: 50%;
}

.flwJnIg-5 {
    left: -36%;
    top: 60%;
}


.flwCir {
    width: 24px;
    height: 24px;
    background-image: radial-gradient(203.72% 154.55% at 86.11% 80.56%, #FF8145 0%, rgba(237, 138, 91, 0.870573) 18.23%, rgba(255, 168, 126, 0.710118) 40.83%, rgba(255, 184, 150, 0.49329) 71.37%, rgba(255, 191, 160, 0.384877) 86.64%, rgba(255, 224, 209, 0.29) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 50%;
    position: absolute;
}

.flwCir-1 {
    top: 35%;
    right: 12.75%;
}
.flwCir-2 {
    top: -5%;
    right: -18.5%;
}
.flwCir-3 {
    left: -52%;
}
.flwCir-4 {
    top: 0%;
    right: -28%;
}
.flwCir-5 {
    left: -20.5%;
}

@media only screen and (max-width: 1299px)
{
    // .flwCnt {
    //     padding: 4.25rem 0px;
    // }

    .flwChStr
    {
        padding: 0rem 1.625rem 0rem 0rem;
    }

    .flwChR {
        margin: 0px -3rem;
    }
    
    .flwChC {
        padding: 0px 3rem;
    }
    
    .flwJnIg-2 {
        right: -15%;
        top: -6%;
    }
    
    .flwCir-2 {
        right: -9.5%;
    }
    
    .flwJnIg-4 {
        right: -40%;
    }
    
    .flwCir-4 {
        right: -23%;
    }
    
    .flwJnIg-5 {
        left: -30%;
    }
    
    .flwCir-5 {
        left: -14.5%;
    }
    
    .flwJnIg-1 {
        right: -33%;
    }
    
    .flwCir-1 {
        right: -3.25%;
    }
}

@media only screen and (min-width: 1200px)
{
    .flwJnIg-1 .mb {
        display: none;
    }   
}
@media only screen and (max-width: 1199px)
{
    .flwC-lt {
        text-align: center;
    }
    
    .flwChStr {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        background: linear-gradient(251.91deg, #DFE5FE 1.05%, #ECF3FA 43.91%, #E3E7FA 98.72%);
        border: 0.357473px solid #FFFFFF;
        box-shadow: 0px 1.42989px 13.9414px rgba(171, 156, 208, 0.25);
        border-radius: 8.93683px;
        padding: 2rem;
        margin-bottom: 3rem;
    }
    
    .flwLblStr {
        flex: 0 0 50%;
        padding: 12px 1rem;
    }
    
    .flwLblStr .flwLbl {
        width: 100%;
    }
    
    .flwJnIg-1 .pc {
        display: none;
    }    
    
    .flwJnIg-1 {
        right: 84%;        
        top: 184%;
    }

    .flwCir-1 {
        right: 83.5%;
        top: 120%;
    }

    // .flwCnt {
    //     padding: 3.25rem 0px;
    // }    
}
@media only screen and (max-width: 991px)
{
    .flwChR {
        margin: 0px -1.5rem;
    }
    
    .flwChC {
        padding: 0px 1.5rem;
    }
    
    .flwCh-353212 {
        width: 233px;
        min-height: 132px;
    }
    
    .flwCh-253171 {
        width: 203px;
        min-height: 131px;
    }
    
    .flwCh-227154 {
        width: 187px;
        min-height: 114px;
        margin: 1.75rem 0px;
    }
    
    .flwCh-237161 {
        width: 197px;
        min-height: 121px;
    }
    
    .flwChTx {
        font-size: 16px;
    }
    
    .flwChCdV2 h2 {
        font-size: 24px;
    }
    
    .flwChCdV2 h6 {
        font-size: 11px;
    }
    
    .flwChCdLbl .flwLbl {
        min-width: auto;
    }
    
    .flwLbl {
        font-size: 12px;
        padding: 5px 17px;
    }

    .flwLblStr .flwLbl {
        font-size: 16px;
        padding: 8px 16px;
    }


    .flwCir {
        width: 16px;
        height: 16px;
    }

    .flwChCdV2,
    .flwChCd
    {
        border-radius: 15px;
    }


    .flwJnIg-1 {
        top: 165%;
    }   
    
    
    .flwJnIg-2 {
        right: -18%;
        top: -25%;
    }
    
    .flwJnIg-4 {
        right: -31%;
        bottom: 43%;
    }   
    
    .flwCir-4 {
        right: -10%;
    }
    
    .flwJnIg-5 {
        left: -27%;
    }    
    
    .flwCir-5 {
        left: -12.5%;
    }
    
    .flwJnIg-3 {
        left: -36%;
    }
    
    .flwCir-3 {
        left: -37%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px)
{
    .flwJnIg-1 img {
        width: 10px;
    }

    .flwJnIg-4 img {
        width: 100px;
    }

    .flwJnIg-3 img {
        width: 100px;
    }

    .flwJnIg-5 img {
        width: 69px;
    }
}
@media only screen and (min-width: 768px)
{
    .flwJnIg .sm
    {
        display: none;
    }
}
@media only screen and (max-width: 767px)
{
    .flwJnIg .pc,.flwJnIg .mb {
        display: none;
    }
    
    .flwChStr {
        margin-bottom: 1.75rem;
    }
    
    .flwCh-353212 {
        width: 126px;
        min-height: 100px;
        padding: 8px 0px;
    }
    
    .flwCh-253171 {
        width: 92px;
        min-height: 68px;
    }
    
    .flwCh-227154 {
        width: 82px;
        min-height: 56px;
    }
    
    .flwCh-237161 {
        width: 85px;
        min-height: 58px;
    }
    
    .flwChTx {
        font-size: 10px;
        line-height: normal;
    }
    
    .flwChCdLbl {
        margin: 8px 0px 0px;
    }
    
    .flwLbl {
        font-size: 8px;
        padding: 2px 8px;
        line-height: normal;
    }

    .flwChCdLbl .flwLblCnt
    {
        font-size: 0px;
    }
    
    .flwChCdLbl .flwLblCnt:nth-child(2),
    .flwChCdLbl .flwLblCnt:nth-child(3)
    {
        margin: 4px 2px;
    }
    
    .flwChCd {
        border-radius: 8.93683px;
    }
    
    .flwChCdV2 {
        border-radius: 8.93683px;
        padding: 4px 4px;
    }
    
    .flwChCdV2 h2 {
        font-size: 14px;
        line-height: normal;
    }
    
    .flwChCdV2 h6 {
        font-size: 5.00462px;
        line-height: normal;
    }
    
    .flwChTx.v3 {
        max-width: 83%;
    }



    .flwCir {
        width: 8px;
        height: 8px;
    }
    
    .flwChR {
        margin: 0px -18px;
    }
    
    .flwChC {
        padding: 0px 18px;
    }
    
    .flwJnIg-2 {
        right: -16%;
        top: -7%;
    }
    
    .flwCir-2 {
        right: -10.5%;
    }
    
    .flwJnIg-3 {
        left: -49%;
    }
    
    .flwCir-3 {
        left: -51%;
    }
    
    .flwJnIg-4 {
        right: -38%;
        bottom: 46%;
    }
    
    .flwCh-227154 {
        margin: 18px 0px;
    }
    
    .flwCir-4 {
        right: -19%;
    }
    
    .flwJnIg-5 {
        left: -21%;
    }
    
    .flwCir-5 {
        left: -15.5%;
    }
    
    .flwJnIg-1 {
        top: 149%;
        right: 76%;
    }
    
    .flwCir-1 {
        right: 75.875%;
        top: 117%;
    }



    .flwChStr {
        padding: 26px 27px 20px;
    }
    
    .flwLblStr .flwLbl {
        font-size: 10px;
        padding: 6px 12px;
    }
    
    .flwLblStr {
        padding: 0.5rem;
    }
}
@media only screen and (max-width: 399px)
{
    .flwChStr {
        padding: 16px 18px 20px;
    }
}
/*--||
Flow Chart Content Use End
||--*/
.rdmpdv{
    // background: url('../assets/images/hiwbg.png') no-repeat;
    // background-size: cover;
    position: relative;
    &:after{
        content:'';
        position: absolute;
        // background: url('../assets/images/hiwbgbt.png') no-repeat;
        // background-size: cover;
        background: linear-gradient(120.41deg, rgba(106, 222, 255, 0.46) 29.35%, rgba(89, 64, 244, 0.46) 95.94%);
        opacity: 0.42;
        filter: blur(250px);
        width: 940px;
        height: 870px;
        bottom: 0;
        right:0px;
        @media (min-width:576px) and (max-width: 991px){
            width: auto;
        }
        @media(max-width: 576px){
            width: 183.4px;
            height: 410.59px;
            right: 0;
            filter: blur(48.8281px);
        }
    }
    &:before{
        content:'';
        position: absolute;
        // background: url('../assets/images/hiwbgtp.png') no-repeat;
        // background-size: cover;
        width: 841px;
        height: 930px;
        left: 0;
        top:0;
        background: linear-gradient(138.54deg, #FF2A00 1.68%, rgba(248, 185, 96, 0.8) 41.29%);
        opacity: 0.1;
        filter: blur(200px);
        @media (min-width:576px) and (max-width: 991px){
            width: auto;
        }
        @media(max-width: 576px){
            width: 164.26px;
            height: 441.03px;
            left: 0;
            filter: blur(39.0625px);
        }
    }
    h3{
        font-weight: 800;
        font-size: 50px;
        color: $secondary;
        margin-bottom:75px;
        @media(max-width: 575px){
            font-size: 24px;
            text-align: center;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                background: #0C4241;
                height: 2px;
                width: 85px;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }
            img{
                display: none;
            }
        }
    }
}
.trdrm{
    position: relative;
    padding: 137px 0 200px;
    z-index: 90;
    .trdrmrw{
        margin-top: 84px;
        @media(max-width:991px){
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    @media(max-width:575px){
        padding-bottom: 0;
        padding-top: 35px;
        .trdrmsmbg{
            width: 160px;
        }
    }
    &:before{
        content:'';
        position: absolute;
        // background: url('../assets/images/trdrmbg.png') no-repeat;
        // background-size: cover;
        // width:706px;
        // height: 1162px;
        // top: -29rem;
        // left: 0;
        // @media(max-width: 1199px){
        //     width: auto;
        // }
        
        display: block;
        background: linear-gradient(307.48deg, rgba(118, 253, 156, 0.4) 14.97%, rgba(253, 138, 98, 0.16) 62.19%);
        opacity: 0.53;
        filter: blur(100px);
        height: 762px;
        width: 773px;
        top: -179px;
        left: -267px;
        @media(max-width: 575px){
            display: none;
        }
    }
    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 61px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        // color: white;
        // text-shadow: -1px -1px 0 #0c4241, 1px -1px 0 #0c4241, -1px 1px 0 #0c4241, 1px 1px 0 #0c4241;
        margin-bottom: 0;
        text-align: center;
        position: relative;
        -webkit-text-stroke: 2px #0c4241;
        &:after{
            content: attr(data-text);
            position: absolute;
            left: 50%;
            -webkit-text-stroke: 0;
            color: white;
            pointer-events: none;
            transform: translateX(-50%);
        }
        @media(max-width:575px){
            text-align: center;
        }        
        @media(max-width:991px){
            font-size: 35px;
        }
        @media(max-width:575px){
            font-size: 24px;
            text-align: center;
        }
    }
    h2{
        font-weight: 800;
        font-size: 50px;
        color: $secondary;
        text-align: center;
        @media(min-width:992px) and (max-width: 1199px){
            font-size: 35px;
            margin-bottom: 3rem;
        }
        @media(max-width: 575px){
            font-size: 24px;
        }
    }
    .trdrmdt{        
        @media(max-width:991px){
            margin-top: 1.5rem;
        }
        h3{
            font-weight: 800;
            font-size: 32px;
            color: $secondary;
            margin-bottom: 1.5rem;
            @media(min-width:992px) and (max-width: 1199px){                
                font-size: 24px;
            }
            @media(max-width: 575px){
                font-size: 24px;
                text-align: center;
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            color: $primary;
            margin-bottom: 2rem;
            @media(min-width:992px) and (max-width: 1199px){                
                font-size: 15px;
            }
            @media(max-width: 575px){
                font-size: 14px;
                text-align: center;
            }
        }
        h5{
            font-weight: 500;
            font-size: 18px;
            color: $primary;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            img{
                margin-right: 6px;                
            }
            .trdrmdtgmob{
                display: none;
            }
            @media(max-width:575px)                {
                .trdrmdtg{
                    display: none;
                }
                .trdrmdtgmob{
                    display: block;
                }
            }
            @media(min-width:992px) and (max-width: 1199px){                
                font-size: 14px;
            }
            @media(max-width: 575px){
                font-size: 14px;
            }
        }
    }
}
.tkmsc{
    position: relative;
    padding-bottom: 220px;
    background: radial-gradient(119.47% 119.47% at 49.4% 14.32%, #FAFBFB 0%, rgba(250, 251, 251, 0.49) 100%);
    @media(max-width:991px){
        padding-bottom: 140px;
    }
    &:after{
        content: '';
        position: absolute;
        background: linear-gradient(307.48deg, rgba(118, 253, 156, 0.4) 14.97%, rgba(253, 138, 98, 0.16) 62.19%);
        opacity: 0.53;
        filter: blur(100px);
        width: 571px;
        height: 571px;
        right: 0;
        top: -26rem;
        transform: rotate(180deg);
        @media(max-width: 767px){
            display: none;
        }
    }
    .index-one {
        overflow: hidden;
    }

    @media(max-width:575px){
        .token_mics_cd{
            margin-top:30px;
        }
    }
    
    .token_mics_cd h2 {
        font-style: normal;
        line-height: 36px;
        font-weight: 700;
        font-size: 61px;
        letter-spacing: 0.05em;
        text-transform: uppercase;        
        position: relative;
        -webkit-text-stroke: 2px #0c4241;
        &:after{
            content: attr(data-text);
            position: absolute;
            left: 50%;
            -webkit-text-stroke: 0;
            color: white;
            pointer-events: none;
            transform: translateX(-50%);
        }
    }
    
    .token_mics_cd h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 50px;
        color: #455252;
    
    }
    
    .token_mics_cd p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #455252;
        max-width: 534px;
        margin-top: 25px;
    }
    
    .token_total {
        position: relative;
    }
    
    .token_cd1_bg {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 1;
    }
    
    .token_mics_cd {
        position: relative;
        z-index: 2;
        text-align: center;
        margin-top: 55px;
    
    }
    
    .token_cd2 h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        color: #0C4241;
        margin-bottom: 4px;
    }
    
    .token_cd2 h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #455252;
    }
    
    .token_cd2 {
        position: relative;
        z-index: 2;
        top: 202px;
    }
    
    .line_1_nd {
        position: absolute;
        top: 130px;
        z-index: 2;
        left: 32px;
    }
    
    .line_2_nd {
        position: absolute;
        top: 287px;
        z-index: 2;
        left: 19.5rem;
    
    }
    
    .line_3_nd {
        position: absolute;
        top: 326px;
        z-index: 2;
        left: 38rem;
    
    }
    
    .line_4_nd {
        position: absolute;
        top: 287px;
        z-index: 2;
        right: 19.5rem;
    
    }
    
    .line_5_nd {
        position: absolute;
        top: 130px;
        z-index: 2;
        right: 32px;
    }
    
    .token_cd2_in {
        padding-left: 12px;
        padding-right: 12px;
    }
    
    
    
    
    @media (min-width:1330px) {
        .container_1 {
            max-width: 1320px;
        }
    
    }
    
    @media (min-width:1200px) and (max-width:1329px) {
        .line_2_nd {
            position: absolute;
            top: 249px;
            z-index: 2;
            left: 16.5rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 285px;
            z-index: 2;
            left: 32rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 252px;
            z-index: 2;
            right: 15.5rem;
        }
    
        .token_cd2 {
            position: relative;
            z-index: 2;
            top: 170px;
        }

        .container_1 {
            max-width: 1140px;
        }
    
    }
    
    @media (min-width:992px) and (max-width:1199px) {
        .token_mics_cd h2 {
            font-size: 36px;
        }
    
        .token_mics_cd h3 {
            font-size: 30px;
    
    
        }
    
        .token_mics_cd {
            margin-top: 1rem;
    
        }
    
        .line_1_nd {
            top: 106px;
            left: 32px;
        }
    
        .line_2_nd {
            top: 197px;
    
            left: 13.5rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 230px;
            z-index: 2;
            right: 26.5rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 208px;
            z-index: 2;
            left: 39rem;
        }
    
        .line_5_nd {
            position: absolute;
            top: 98px;
            z-index: 2;
            right: 12px;
        }
    
        .token_cd2 {
            position: relative;
            z-index: 2;
            top: 170px;
        }
    
    }
    
    @media (min-width:768px) and (max-width:991px) {
        .token_cd1_bg {
            position: absolute;
            top: 195px;
            right: 0px;
            left: 0px;
            z-index: 1;
        }
    
        .line_1_nd {
            position: absolute;
            top: 244px;
            z-index: 2;
            left: 8rem;
        }
    
        .line_2_nd {
            position: absolute;
            top: 275px;
            z-index: 2;
            left: 13.5rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 289px;
            z-index: 2;
            left: 20.5rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 269px;
            z-index: 2;
            right: 13.4rem;
        }
    
        .line_5_nd {
            position: absolute;
            top: 233px;
            z-index: 2;
            right: 8rem;
        }
    
    
    }
    
    @media (max-width:991px) {
        .phone_card_res_1 {
            background: linear-gradient(180deg, rgba(150, 199, 214, 0.1) 0%, rgba(200, 240, 252, 0.06) 100%);
            border: 1px solid rgba(37, 136, 134, 0.15);
            box-shadow: -9px 8px 25px rgba(0, 0, 0, 0.13);
            border-radius: 5px;
            padding: 10px 10px;
            min-width: 91px;
        }
    
        .token_cd2 h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #0C4241;
        }
    
        .token_cd2 h5 {
            font-size: 10px;
            margin-bottom: 0px;
    
        }
    
        .token_mics_cd h2 {
            font-size: 24px;
    
        }
    
        .token_mics_cd h3 {
            font-size: 24px;
    
        }
    
        .token_mics_cd p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #455252;
            max-width: 413px;
            margin-top: 20px;
        }
    
        .token_cd1_bg img {
            height: 162px;
    
        }
    
        .token_cd1_bg {
            position: absolute;
            top: 133px;
            right: 0px;
            left: 45px;
            z-index: 1;
        }
    
        .nd-img_1 {
            display: none;
        }
    
        .token_cd2 {
            top: 106px;
        }
    
    
    }
    
    @media (min-width:576px) and (max-width:767px) {
        .line_1_nd {
            position: absolute;
            top: 249px;
            z-index: 2;
            left: 5rem;
    
        }
    
        .line_2_nd {
            position: absolute;
            top: 276px;
            z-index: 2;
            left: 9.7rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 289px;
            z-index: 2;
            left: 15.4rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 278px;
            z-index: 2;
            right: 9.7rem;
        }
    
        .line_5_nd {
            position: absolute;
            top: 251px;
            z-index: 2;
            right: 5rem;
        }
    
        .token_cd1_bg {
            position: absolute;
            top: 133px;
            right: 0px;
            left: 0px;
            z-index: 1;
        }
    
    }
    
    
    @media (min-width:992px) {
        .nd_img_2 {
            display: none;
        }
    
    
    }
    
    @media (min-width:360px) and (max-width:575px) {
        // .line_1_nd {
        //     position: absolute;
        //     top: 219px;
        //     z-index: 2;
        //     left: 6rem;
    
        // }
    
        .token_mics_cd p {
            margin-top: 5px;
        }

        .token_mics_cd  h3{
            margin-bottom: 25px;
        }
    
        .line_2_nd {
            position: absolute;
            top: 245px;
            z-index: 2;
            left: 5.9rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 252px;
            z-index: 2;
            left: 9.7rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 245px;
            z-index: 2;
            right: 5.8rem;
        }
    
        .line_5_nd {
            position: absolute;
            top: 220px;
            z-index: 2;
            right: 2rem;
        }
    
        .token_cd1_bg {
            position: absolute;
            top: 168px;
            right: 0px;
            left: 0px;
            z-index: 1;
        }
    
        .token_cd1_bg img {
            height: auto;
        }
    
        .token_mics_cd p {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: #455252;
            max-width: 267px;
        }
    
        .token_cd2 {
            top:89px;
        }
    
        .token_cd2 .token_cd2_in {
            padding-right: 5px;
            padding-left: 5px;
        }
    
        .line_1_nd {
            position: absolute;
            top: 219px;
            z-index: 2;
            left: 2rem;
        }
    
        .container_1 {
            max-width: 355px;
        }
    
    }
    
    @media (max-width:359px) {
        .line_1_nd {
            position: absolute;
            top: 233px;
            z-index: 2;
            left: 6rem;
    
        }
    
        .line_2_nd {
            position: absolute;
            top: 200px;
            z-index: 2;
            left: 5.1rem;
        }
    
        .line_3_nd {
            position: absolute;
            top: 205px;
            z-index: 2;
            left: 8.1rem;
        }
    
        .line_4_nd {
            position: absolute;
            top: 200px;
            z-index: 2;
            right: 4.9rem;
        }
    
        .line_5_nd {
            position: absolute;
            top: 182px;
            z-index: 2;
            right: 2rem;
        }
    
        .token_cd1_bg {
            position: absolute;
            top: 133px;
            right: 0px;
            left: 0px;
            z-index: 1;
        }
    
        .token_cd1_bg img {
            height: auto;
        }
    
        .token_mics_cd p {
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            color: #455252;
            max-width: 186px;
        }
    
        .token_cd2 {
            top: 33px;
        }
    
        .token_cd2 .token_cd2_in {
            padding-right: 5px;
            padding-left: 5px;
        }
    
        .line_1_nd {
            position: absolute;
            top: 185px;
            z-index: 2;
            left: 2rem;
        }
    
        .container_1 {
            max-width: 315px;
        }
    
    }
}

/* *********************** */
.quadrix_lor {
    background-image: url("../assets//images/bg_2_eth.png");
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 1178px;
    border-radius: 25px;
    padding: 38px 20px;
    position: relative;
    margin: auto;
    min-height: 441px;

}

.nd_btn_buy {
    padding: 11px 31px;
    background: #0C4241;
    border-radius: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #F3FFFF;
    margin-top: 38px;
    &:hover{
        background: #258886;
        color: #FFFFFF;
        box-shadow: none;
        transition: all .5s;
    }
}

.quadrix_lor h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    color: #0C4241;
    padding-top: 8px;
}

.quadrix_lor h3 {
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    color: #0C4241
}

.quadrix_lor p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #455252;
}

.quadrix_lor h4 {
    font-weight: 500;
    font-size: 20px;
    color: #2C635F;
}

.quadrix_lor p span {
    color: #258886;
}

.round_frn {
    background: #258886;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: 50%;
}

.quadrix_lor_card_1 {
    max-width: 224px;
    background: linear-gradient(180deg, #CAD1FC 0%, rgba(202, 209, 252, 0) 100%);
    filter: drop-shadow(0px -1px 43px rgba(0, 0, 0, 0.13));
    backdrop-filter: blur(50px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 25px;
    display: inline-block;
    padding: 20px 20px;
}

.quadrix_lor_card_1 h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    color: #0C4241;
    padding-top: 10px;



}

.quadrix_lor_card_1 h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #0C4241;
    padding-top: 10px;
}

.quadrix_lor .quadrix_lor_card_1 p {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #455252;
    padding-top: 10px;
}

.quadrix_lor_card_1 {
    position: absolute;
    top: -80px;
    left: -27px;
    transform: rotate(-22.62deg);
}

.quadrix_lor_card_2 {
    position: absolute;
    top: 110px;
    right: -65px;
    left: auto;
    transform: rotate(22.17deg)
}
.buy_token_cd_draw{
    margin-bottom: 12px;
}

.quadrix_lor_card_1 ul {
    list-style-image: url('../assets/images/pointer_dn.png');
    padding-inline-start: 20px;

}

.quadrix_lor_card_1 ul li {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #455252;
}

.quadrix_lor_card_2 h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #0C4241;

}

.hr_dh_top {
    padding-top: 260px;
    padding-bottom: 93px;
    overflow-x: hidden;
    @media(max-width:575px){
        padding-bottom: 10px;
    }

}

@media (min-width:1420px) {
    .container_2 {
        max-width: 1420px;
    }

}

@media (min-width:1200px) and (max-width:1380px) {

    /* .container_2{
        max-width: 991px;

    } */
    .quadrix_lor {
        max-width: 959px;

    }

}

@media (max-width:1199px) {
    .quadrix_lor h2 {
        font-size: 30px;
    }

    .quadrix_lor h4 {
        font-size: 12px;
    }

    .quadrix_lor h3 {
        font-size: 30px;

    }

    .quadrix_lor_card_1 h1 {
        font-size: 18px;

    }

    .quadrix_lor_card_1 h5 {
        font-size: 14px;
    }

    .quadrix_lor {
        max-width: 668px;

    }

    .quadrix_lor_card_1 {
        /* max-width: 224px; */
        left: -97px;
    }

    .quadrix_lor_card_2 {
        /* max-width: 184px; */
        left: auto;
    }

    .quadrix_lor p {
        font-size: 12px;

    }

}

@media (max-width:991px) {
    .quadrix_lor_card_1 {
        display: none;
    }

    .quadrix_lor_card_2 {
        display: none;
    }

    .hr_dh_top {
        padding-top: 10px;

    }
    .quadrix_lor{
        min-height: auto;
        padding: 20px 20px;

    }
    .nd_btn_buy{
        margin-top: 10px;
    }


}

@media (max-width:767px) {
    .quadrix_lor h2 {
        font-size: 14px;
    }

    .quadrix_lor h4 {
        font-size: 10px;

    }

    .quadrix_lor h3 {
        font-size: 14px;
    }

    .round_frn {
        background: #258886;
        min-width: 6px;
        min-height: 6px;
        max-width: 6px;
        max-height: 6px;
        border-radius: 50%;
    }
    .nd_btn_buy{
        padding: 7px 17px;
        background: #0C4241;
        border-radius: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #F3FFFF;
        margin-top: 10px;
    }


}
/* Home CSS End */
/* Footer CSS Start */
footer{
    background: url('../assets/images/footerbg.png') no-repeat;
    background-size: 100% 100%;
    @media(max-width:767px){
        margin-top:0;
    }
    .fotpad{
        padding: 130px 0 93px;
        @media(max-width:767px){
            padding: 30px 0 15px;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            color: $primary;
            margin: 1.5rem 0;
            @media (max-width:1199px){
                font-size: 14px;
            }
        }
        .socdv{
            display: flex;
            flex-direction: column;            
            h4{
                font-weight: 600;
                font-size: 32px;
                color: #0C4241;
                margin-bottom: 25px;
                @media(min-width: 576px) and (max-width: 767px){
                    font-size: 20px;
                }
                @media(max-width:575px){
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .socdvdt{
                display: flex;                
            }
            .socdvdtcnt{
                width: 50px;
                height: 50px;
                background: transparent;
                border-radius: 15px;
                border: 1px solid #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover{
                    background: #afe0fd;
                    transition: all .5s;
                    img{
                        transform: rotate(45deg);
                        transition: all .5s;
                    }
                }
                @media(max-width:575px){
                   width:40px;
                   height:40px;
                   img{
                    width: 24px;
                   }
                }
            }
        }
        h4{
            font-weight: 500;
            font-size: 18px;
            color: $secondary;
            margin-bottom: 1.5rem;       
            @media(min-width: 992px) and (max-width:1199px){
                font-size: 16px;
            }
            @media(max-width: 767px){
                font-size: 14px;
                font-weight: 700;
                margin: 1rem 0;
            }
        }
        ul li{
            list-style: none;
            margin-bottom: 1rem;
            a{
                font-weight: 400;
                font-size: 16px;
                color: $primary;
                &:hover{
                    color: #258886;
                    transition: all .5s;
                }
                @media(max-width:1199px){
                    font-size: 14px;
                }
                
            }
        }
        h3{
            font-weight: 600;
            font-size: 18px;
            color: $secondary;
            margin-bottom: 1.5rem; 
            @media(max-width:1199px){
                font-size: 16px;
                font-weight: 700;
                margin: 1rem 0 0.5rem;
            }
        }
        h6{
            font-weight: 400;
            font-size: 16px;
            color: $primary;
            margin-top: 1.5rem;
            opacity: 0.5;
            line-height: 1.5;
            @media(max-width:1199px){
                font-size: 14px;
            }
        }
        .input-group{
            background: #258886;
            box-shadow: 2px 12px 50px rgba(4, 21, 21, 0.43);
            border-radius: 40px;
            @media(min-width:768px) and (max-width:1199px){
                width: 50%;
            }
            input{
                background: #258886;
                border-radius: 40px;
                height: 52px;
                border-color: transparent;
                background: linear-gradient(180deg, #D9DEF2 0%, #ECF3FA 53.28%, #D9DEF2 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                opacity: 0.7;
                font-weight: 400;
                font-size: 14px;
                color: #fff;
            }
            .input-group-append{
                background: #0C4241;
                border-radius: 40px;
                button{
                    width: 150px;
                    font-weight: 500;
                    font-size: 16px;
                    color: #F3FFFF;
                    text-align: center;                    
                }
                &:hover{
                    background: #104e4d;
                    color: #FFFFFF;
                    box-shadow: none;
                    transition: all .5s;
                }
            }
        }
        p.cprts{
            font-size: 16px;
            color: $gray;
            padding: 15px 0 0;
            margin-bottom: 0;
            border-top: 1px solid #C2CACA;
            text-align: center;
            @media(max-width:1199px){
                font-size: 12px;
            }
        }
    }
}
/* Footer CSS End */
/* Video CSS Start */
.rh5v-DefaultPlayer_component{
    background: #F3FFFF;
    box-shadow: 4px 8px 100px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
    padding: 15px 15px 9px 15px;
    width: 687px;
    height: 473px;
    @media(max-width:1279px){
        width: 100%;
        height: 100%;
    }
    .rh5v-DefaultPlayer_video{
        border-radius: 27px;
        width: 657px; 
        height: 437px;
        object-fit: cover;
        @media(max-width:1279px){
            height: 100%;
        }
       
    }
    .rh5v-Overlay_inner{
        border-radius: 50%;
        background: #fff;
        svg{
            fill: #05A9A6;
        }
    }
    .rh5v-DefaultPlayer_controls{
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 25px;
    }
}
/* Video CSS End */

/* YT CSS Start */
.embytdv{
    background: #F3FFFF;
    box-shadow: 4px 8px 100px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
    padding: 15px 15px 9px 15px;
    width: 687px;
    height: 473px;
    @media(max-width:1279px){
        width: 100%;
        height: 100%;
        min-width: 100%;
    }
    embed, iframe, video{
        border-radius: 27px;
        width: 657px; 
        height: 437px;
        object-fit: cover;
        border:none;
        min-width: 100%;
        .html5-video-player{
            width: 100%;
            min-width: 100%;
        }
        @media(max-width:1279px){
            // height: 100%;
            width: 100%;
            min-width: 100%; 
        }
        @media(max-width:575px) {
            height: 309px;
        }
    }
}
/* YT CSS End */

.primary-btn.w-179-h-53{
    min-width: 179px;
    min-height: 53px;
}
.primary-btn.w-190-h-62{
    min-width: 190px;
    min-height: 62px;
}
.primary-btn.w-187-h-53{
    min-width: 187px;
    min-height: 53px;
    font-size: 18px;
}
.primary-btn.w-202-h-62{
    min-width: 202px;
    min-height: 62px;
    font-weight: 600;
    font-size: 20px;
    @media(max-width: 575px){
        min-width: 136px;
        min-height: 43px;
        font-size: 14px;
    }
}
.marquee{
    @media(max-width: 1450px){
        margin-right: 100px;
    }
    @media(max-width:575px){
        margin-right: 0px;
    }
}
.marquee-container{
    .overlay::before, .overlay::after{
        background: transparent;
    }
}
.primary-btn.w-162-h-65{
    min-width: 114px;
    min-height: 42px;
    background: #0C4241;
    box-shadow: 2px 12px 46px rgba(4, 21, 21, 0.15);
    border-radius: 35px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F3FFFF;
    margin:auto;
}
.trmscntdv{
    margin-bottom: 45px;    
    display: flex;
    justify-content: center;
    .trmscntdvimg{
        img{
            @media(max-width:575px){
                width: 60px;
                height: 30px;
            }
        }
    }
}
@media(max-width:991px){
    .order-2p{
        order:2;
    }
    .order-1p{
        order:1;
    }
}

/* Mobile frame Css */
.MblDvice {
    width: 470px;
    height: 950px;
    background: url(../assets/images/mbl-frame.svg)no-repeat;
    padding: 7rem 43px 4rem;
    margin: auto;
    @media(max-width:400px){
        width: 100% !important;
        height: 674px !important;
        background-size: contain !important;
        background-position: center;
        padding-top: 5rem;
    }
    @media(max-width:575px){
        width: 100%;
        background-size: 100% 100%;
        height: auto;
    }
}
.MblDvice .MblBody {
    width: 100%;
    position: relative;
    height: 100%;
    margin: 0 2px;
}
.MblDvice .MblBody .MblBdyCnt{
    height: 100%;
    overflow-y: auto;
}
.MblDvice .MblBody .MblBdyCnt::-webkit-scrollbar,
.MblDvice .MblBody .MblBdyCnt::-webkit-scrollbar,
.MblDvice .MblBody .MblBdyCnt::-webkit-scrollbar {
    display: none;
     /* Safari and Chrome */ }

.MblDvice .MblBody .MblBdyCnt {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */ 
}
/*  Mobile frame Css End   */

/* Crypto Purchase Css */
    .FiatCrptoCnt h4{
        font-weight: 800;
        font-size: 36px;
        line-height: 49px;
        color: #2C635F;
        margin: 0 32px 15px;
        @media(max-width:575px){
            font-size: 22px;
            line-height: 30px;
        }
    }
    .FiatCrptoCnt p{
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #818B8A;
        @media(max-width:575px){
            font-size: 11.7591px;
            margin-bottom: 0;
        }
    }
    .MblBtn{
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #F3FFFF;
        width: 100%;
        min-height:60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .8s all ease-in-out;
        @media(max-width:575px){
            min-height: 52px;
            font-size: 11.3603px;
        }
    }
    .MblBtnPrmry{
        background: #358882;
        border:1px solid #358882;
    }
    .MblBtn:hover{
        background: #2b5a57;
        color:#fff;
        transition: .8s all ease-in-out;
    }
    .FiatCrptoImg {
        margin-bottom: 45px;
        @media(max-width:575px){
            margin-bottom:28px;            
        }        
    }
    .FiatCrptoCnt{
        margin-bottom: 20px !important;
        p{
            margin-bottom: 0;
        }
    }
    .MblSldrBtn{
        padding-top: 0 !important;
    }
/* Crypto Purchase Css End */


/* Place your order Css */
    .PlcYrOrdrHdd h4{
        font-weight: 700;
        font-size: 24px;
        line-height: 145%;
        color: #2C635F;
        @media(max-width: 575px){
            font-size: 22px;
        }
    }
    .PayInptGrp .form-control{
        border: 1px solid #358882;
        border-radius: 50px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #2C635F;
        min-height:54px;
        padding-left: 20px;
        box-shadow:unset;
        border-right: unset;
        @media(max-width: 575px){
            font-size: 12px;
        }

    }
    .SlctFlgDd {
        background: #358882;
        display: flex;
        align-items: center;
        padding: 5px 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #F3FFFF;
        box-shadow:unset;
        @media(max-width:575px){
            padding: 5px 15px;
            font-size: 12px;
        }
    }
    .SlctFlgDd:focus{
        box-shadow:unset;
    }
    .SlctFlgDd:hover{
        color: #F3FFFF;
    }
    .SlctFlgDdRt{
        border-radius: 0px 27px 27px 0px;
    }
    .SlctFlgSec .dropdown-item{
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #2C635F;
    }
    .SlctFlgSec .dropdown-toggle::after{
        background:url(../assets/images/drpdwn-arrw.svg)no-repeat;
        border:unset;
        width: 9px;
        height: 7px;
    }
    .PayRcvsec{
        border: 1px solid #E2EBEB;
        border-radius: 25px;
        padding:20px 16px;
    }
    .PayInptGrp .FrmLbl{
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #818B8A;
    }
    .InptInsRd{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #F95B51;
    }
    .InptBttmSec{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .CoinInsCnt{
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2C635F;
    }
    .MblSldrInpt .form-control{
        border: 1px solid #358882;
        border-radius: 50px;
        min-height:54px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #358882;
        padding: 6px 20px;
        box-shadow:unset;
    }
    .MblSldrInpt .FrmLbl{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #818B8A;
    }
    .MblBtnScndry{
        border: 1px solid #358882;
        border-radius: 50px;
        color: #2C635F;
    }
    .SwpExcngBtn .btn{
        background:#358882;
        border-radius:50%;
        width:70px;
        height: 70px;
        border:7px solid #B8DEDB;
        transition: 1s all ease-in-out;
    }
    .SwpExcngBtn .btn:hover{
        animation: pulse 2s infinite both;
        transition: 1s all ease-in-out;
    }
    @keyframes pulse{
        0% {
                box-shadow: 0 0 0 2px #B8DEDB;
        }
        100% {
                box-shadow: 0 0 0 10px transparent;
       
        }
    }
/* Place your order Css End */

/* Verify Contact Css */
    .FlxMdlCntr{
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        min-height:755px;
        @media(max-width: 575px){
            min-height: 500px;
        }
    }
    .PayInptGrpPrpnd .SlctFlgDdLt{
        border-radius: 50px 0 0 50px;
    }
    .PayInptGrpPrpnd.PayInptGrp .form-control{
        border-right: 1px solid #358882;
    }
    .PhnMailTab li a{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #2C635F;
        background: #EFF8F7 !important;
        border-radius: 50px !important;
        min-width:165px;
        min-height:54px;
        display: flex;
        justify-content:center;
        align-items: center;
        text-decoration: none;
        transition: .4s all ease-in-out;
        @media(max-width: 575px){
            min-width: 110px;
            min-height: 44px;
            font-size: 12px;
        }
    }
    .PhnMailTab li a:hover, 
    .PhnMailTab li a.active{
        background: #358882 !important;
        border-radius: 50px;
        color: #F3FFFF !important;
        transition: .8s all ease-in-out;
    }
    .PhnMailTab{
        justify-content:space-between;
        border-bottom:unset;
    }
    .MailInpt .form-control{
        background: #EFF8F7;
        border-radius: 50px;
        border:1px solid transparent;
    }
    .MblsldrModal{
        position:absolute;
        backdrop-filter: blur(3px);
        bottom:0;
    }
    /* .MblsldrModal .modal-content{
        background: #F8F8F8;
        box-shadow: 4px 0px 74px rgb(0 0 0 / 23%);
        border: unset;
        border-radius:30px 30px 0 0 ;
        padding: 25px 20px;
    } */
    .EntCdInpt {
        display: flex;
        justify-content: center;
    } 
    .VrfyCdHdd h4{
        font-weight: 700;
        font-size: 22px;
        line-height: 160%;
        color: #2C635F;
        @media(max-width: 575px)    {
            font-size: 20px;
        }
    }
    .OurIdSec h4{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #2C635F;
        @media(max-width:575px){
            font-size: 15px;
        }
    }
    .EntrCdCnt p{
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: #818B8A;
        @media(max-width:575px){
            font-size: 12px;
        }
    }
    .EntCdInpt .form-control{
        background: #EFF8F7;
        border: 1px solid #818B8A;
        box-shadow: 0px 4px 10px rgba(26, 47, 45, 0.16);
        border-radius: 9px;
        width:34px;
        height:37px;
        margin: 0 6px;
        font-weight: 600;
        font-size: 14px;
        color: #2C635F;
        padding:10px;
        @media(max-width:575px){
            width:30px;
            height: 30px;
            margin: 0 4px;
            padding: 7px;
        }
    }
    
    /* .MblsldrModal .modal-dialog{
        position: absolute;
        bottom: -28px;
    } */
    
/* Verify Contact Css End */

/* Provide card information */
    .CkhBoxCnt .form-group {
        display: block;
        margin-bottom:0;
    }
      
    .CkhBoxCnt .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
      
    .CkhBoxCnt .form-group label {
        position: relative;
        cursor: pointer;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #818B8A;
        padding-left: 22px;
    }
      
    .CkhBoxCnt .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        padding: 5px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 6px;
        border: 1px solid #358882;
        border-radius: 50px;
        margin: 3px 0px;
        position: absolute;
        left: 0;
        
    }
    .CkhBoxCnt .form-group input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 1px;
        width: 10px;
        height: 10px;
        background: #358882;
        border-radius: 50px;
    }
/* Provide card information End */

/* Verify KYC Css */
    .VrfyCntHdd h4{
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #2C635F;
    }
    .VrfyCntHdd h6{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #2C635F;
    }
    .StpCount li{
        width:36px;
        height:7px;
        background: #E4EAE9;
        border-radius: 2px;
        margin:0 3px;
    }
    .StpCount{
        list-style-type:none;
        display: flex;
        justify-content: center;
        padding-left: 0;
    }
    .StpCount li.active{
        background: #C2CACA;
    }
    .VrfyKycBg{
        background: #F8F9F9;
        border-radius: 15px;
        padding:20px;
    }
    .ContrySlct .SlctFlgDd{
        background: #EFF8F7;
        border-radius: 50px;
        min-height:44px;
        color: #2C635F;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    .DcumntSlct.MailInpt .form-control{
        min-height:44px;
        background:#EFF8F7 url(../assets/images/slct-arrw-down.svg) no-repeat right 15px center;
    }
    .ContrySlct .dropdown-toggle::after {
        background:url(../assets/images/slct-arrw-down.svg)no-repeat right  center;
        border: unset;
        width: 12px;
        height: 7px;
    }
    .UpldImgCnt p{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #2C635F;
    }
    .UpldImgCnt ul li{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #72797A;
        margin-bottom: 10px;
    }
    .UpldImgCnt ul li span{
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #72797A;

    }
    .UpldImgShw{
        background: rgba(35, 68, 70, 0.09);
        backdrop-filter: blur(0.5px);
        border-radius: 10px;
        min-height:98px;
        position: relative;
    }
    .ApprvBtn{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
    .FileUpld{
        position: relative;
        overflow: hidden;
        background: #EFF8F7;
        border: 1px dashed #358882;
        border-radius: 10px;
        padding:22px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .FileUpld input {
        position: absolute;
        font-size: 50px;
        opacity: 0;
        right: 0;
        top: 0;
    }
    .CntinuePhon a{
        background: #EFF8F7;
        border: 1px solid #358882;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #2C635F;
        display:inline-flex;
        justify-content: center;
        align-items: center;
        width:100%;
        min-height:55px;
    }
    .CntinuePhon a:hover{
        text-decoration: unset;
    }
    .OrdrPlcedCnt h4{
        font-weight: 700;
        font-size: 22px;
        line-height: 160%;
        color: #2C635F;
    }
    .OrdrPlcedCnt h6{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #72797A;
    }
    .OrdplDtls p{
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: #2C635F;
        margin-bottom: 3px;
        word-wrap: break-word;
    }
    .MdlBrdTp{
        width:110px;
        height: 4px;
        background: #C6CECF;
        box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.14);
        border-radius: 2px;
        margin: 0 auto;
    }
    .VrfyCodeSec{
        background: #F8F8F8;
        box-shadow: 4px 0px 74px rgba(0, 0, 0,  0.23);
        border: unset;
        border-radius: 30px 30px 0 0;
        padding: 25px 14px;
    }
    .OrdrCnform{
        padding: 25px 14px;
    }
    .BttmMdlSec{
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
        position: absolute;
        bottom: 0;
        transition: .5s all ease-in-out;
        left: 0;
        z-index: 99;
        width: 100%;
    }
    .BttmModal  .BttmMdlSec{
        -webkit-transform: translateY(0);
            transform: translateY(0);
        opacity: 1;
        transition: .8s all ease-in-out;
    }
    .BttmModal .MblDvice{
        padding: 7rem 32px 4rem;
    }
/* Verify KYC Css End */
.MuiPaper-root {
    background-color: transparent !important;
}
.MuiStepper-alternativeLabel{
    width: 304px;
    padding: 15px 0;    
    justify-content: center;
    position: relative;
    padding: 29px 0 !important;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width:575px){
        padding: 6px 0 !important;
    }
    .MuiStep-horizontal {
        padding-left: 8px;
        padding-right: 0px;
    }
    .MuiStep-alternativeLabel{        
        flex: 1;
        .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel{
            padding: 5px;
            background: #E8F0F0;
            box-shadow: 0px 4px 54px rgba(53, 136, 129,  0.18);
            border-radius: 50%;
            .MuiStepIcon-root.MuiStepIcon-completed{
                color: #258886;
            }
            .MuiStepIcon-root.MuiStepIcon-active {
                color: #258886;
            }
            .MuiStepIcon-root {
                color: #BDDCDA;
                .MuiStepIcon-text{
                    display: none;
                }
            }            
        }
    }
    .MuiStepConnector-line{
        border:none;
        position: relative;
        &:after{
            background: #E8F0F0;
            box-shadow: 0px 4px 54px rgba(53, 136, 129, 0.18);
            height: 9px;
            width: 134%;
            content:'';
            position: absolute;
        }
    }
}
@media(max-width: 575px){
    .VrfyCdHdd{       
        margin-top:0 !important;
    }  
}
@media(max-width:991px){
    .MobileSlidrSec{
        padding-top: 60px;
    }
}
@media(min-width:992px){
    .hiwweb{
        display: block;
    }
    .hiwmob{
        display: none !important;
    }
}
@media(max-width:991px){
    .hiwweb{
        display: none !important;
    }
    .hiwmob{
        display: block;
    }
}

.inrftr{
    background: unset;
    .fotpad{
        padding: 0 0 25px;
    }
}

/*== start of code for tooltips ==*/
/*== common styles for both parts of tool tip ==*/
/*== pointer tip ==*/
/*== speech bubble ==*/

@media (min-width: 1450px){
    .container-1180{
        max-width: 1180px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .container-1450{
        max-width: 1450px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

.SwpMainPg {
	padding: 40px 0 110px;
    @media(max-width:575px){
        padding: 25px 0 50px;
    }
}
.SwpLqtTitl {
	li {
		a {
			background: #E9F3F3;
			border-radius: 32px;
			font-weight: 600;
			font-size: 18px;
			line-height: 25px;
			color: #829291;
			min-width: 215px;
			min-height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .7s all ease-in-out;
			margin: 3px;
			&:hover {
				background: #258886;
				box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
				border-radius: 32px;
				color: #F3FFFF;
				transition: .7s all ease-in-out;
			}
		}
		a.active {
			background: #258886;
			box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
			border-radius: 32px;
			color: #F3FFFF;
			transition: .7s all ease-in-out;
		}
	}
	display: flex;
	flex-wrap: wrap;
	background: #FFFFFF;
	box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
	border-radius: 32px;
	max-width: 462px;
	margin: 0 auto;
	justify-content: space-between;
}
.UpCmngInHdd {
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;
		color: #258886;
		margin-bottom: 0;
	}
}
.BrdRit {
	border-right: 1px solid #9BA2A1;
}
.UpCmgItm {
	display: flex;
	align-items: center;
}
.UpCmgItmImg {
	img {
		width: 28px;
		height: 28px;
		object-fit: cover;
	}
}
.UpCmngInvnt {
	display: flex;
	align-items: center;
	background: #F3FFFF;
	border: 2px solid #FFFFFF;
	box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.05);
	border-radius: 15px;
	padding: 10px 20px;
	margin-top: 16px;
	display: block;
}
.UpCmgDtls {
	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #829291;
		margin-bottom: 0;
		b {
			font-weight: 700;
		}
	}
	h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		color: #829291;
		margin-bottom: 0;
	}
}
.CmmnWhtBg {
	background: #FFFFFF;
	border: 1px solid #E6EEEE;
	box-shadow: 2px 1px 50px rgba(0, 0, 0, 0.07);
	border-radius: 20px;
}
.SwpHddSec {
	display: flex;
	justify-content: space-between;
}
.SwpHdd {
	h4 {
		font-weight: 600;
		font-size: 20px;
		line-height: 27px;
		color: #1A4E4C;
	}
}
.SwpSec {
	padding: 25px 23px;
}
.SwpSttng {
	a.text {
		border: 1px solid #E9F3F3;
		filter: drop-shadow(0px 2px 30px rgba(7, 37, 28, 0.05));
		min-width: 36px;
		min-height: 36px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: .4s all ease-in-out;
		&:hover {
			background: #DEEDED;
			border: 1px solid #DEEDED;
			transition: .4s all ease-in-out;
		}
	}
}
.SwpFlx {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.WlltAmntSec {
	h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 21px;
		color: #829291;
		margin-bottom: 0;
	}
}
.WlltAmntMxBtn {
	background: #DFE8E7;
	border-radius: 3px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: #258886;
	padding: 2px 6px;
}
.YrSllCnt {
	p {
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #829291;
		margin-bottom: 0;
	}
}
.SellSec {
	border: 1px solid #BEC7C6;
	border-radius: 10px;
	padding: 12px 16px 10px;
}
.SlctTknBtn {
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #258886;
	background: #DEEDED;
	border-radius: 25px;
	padding: 3px 12px 4px 5px;
	box-shadow: unset;
	outline: unset;
}
.TknVlue {
	h4 {
		font-weight: 600;
		font-size: 18px;
		line-height: 25px;
		color: #258886;
		margin-bottom: 0;
	}
}
.UsdBtcAmt {
	h4 {
		font-weight: 600;
		font-size: 13px;
		line-height: 18px;
		color: #829291;
		margin-bottom: 0;
	}
}
.ExchngeBtn {
	background: #FFFFFF;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
	min-width: 56px;
	min-height: 56px;
	border: 4px solid #E6EEEE;
	border-radius: 50%;
	align-items: center;
	display: flex;
	justify-content: center;
}
.TknDtls {
	padding: 0 0 15px;
	position: relative;
}
.ExchngeBtnSec {
	position: absolute;
	right: 0;
	top: -12px;
}
.CnctWlltBtn {
	background: #258886;
	box-shadow: 1px 6px 20px rgba(9, 41, 39, 0.15);
	border-radius: 27px;
	min-height: 54px;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #F3FFFF;
	&:hover {
		background: #1A4E4C;
		transition: .5s all ease;
		color: #fff;
	}
}
.LvChrtSec {
	padding: 30px 20px;
}
.ChrtTknImg.ChrtTknImg1 {
	margin-right: -8px;
	z-index: 0;
}
.ChrtTknCnt {
	h4 {
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
		color: #258886;
		margin-bottom: 0;
	}
}
.ChrtYrsClc {
	ul {
		border: 1px solid #E8F2F2;
		border-radius: 10px;
		padding: 6px;
		display: flex;
		li {
			a {
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				color: #829291;
				padding: 5px 7px 5px 7px;
				display: block;
				margin: 0 1px;
				&:hover {
					background: #258886;
					box-shadow: 1.64619px 2.46929px 24.6929px rgba(8, 49, 48, 0.14);
					border-radius: 4.11548px;
					color: #F3FFFF;
				}
			}
			a.active {
				background: #258886;
				box-shadow: 1.64619px 2.46929px 24.6929px rgba(8, 49, 48, 0.14);
				border-radius: 4.11548px;
				color: #F3FFFF;
			}
		}
	}
}
.ChrtHddSec {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.YouGtCnt {
	h4 {
		font-size: 14px;
	}
}
.GetSlctTkn {
	background: #F3FFFF;
	border: 1px solid #DFE8E7;
	border-radius: 15px;
}
.ChrtAmnt {
	h4 {
		font-weight: 700;
		font-size: 14px;
		line-height: 19px;
		color: #258886;
		span {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #258886;
		}
	}
}
.slider1 {
	.slick-next {
		&:before {
			content: url(../assets/images/sldr-rit-arrow.svg);
			opacity: 1;
		}
		right: -15px;
		top: 40%;
	}
	.slick-prev {
		&:before {
			content: url(../assets/images/slrd-lft-arrow.svg);
			opacity: 1;
		}
		top: 40%;
	}
}
.CllpseSec {
    span.Collapsible__trigger {
        // background: url(../assets/images/cllps-arrw-up.svg) no-repeat calc(100% - 0px) center;
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #829291;
        transition: .4s all ease-in-out;
        width: 100%;
        position: relative;
        display: block;
        &::after{
            content: '';
            position: absolute;
            background: url(../assets/images/cllps-arrw-dwn.svg) no-repeat calc(100% - 0px) center;
            width: 14px;
            height: 7px;
            right: 0;
            top:8px;
            transition: .4s all ease-in-out;
        }        
    }
    span.Collapsible__trigger.is-open{
        &::after{
            transform: rotate(180deg);
            transition: .4s all ease-in-out;
        }       
    }
    span.Collapsible__trigger.is-open ~ .Collapsible__contentOuter{
        overflow: visible !important;
    }
    span.Collapsible__trigger.is-closed ~ .Collapsible__contentOuter{
        overflow: hidden;
    }
    // h2.collapsed {
    //     background-image: url(../assets/images/cllps-arrw-dwn.svg);
    //     transition: .4s all ease-in-out;
    // }
	.card {
		border: none;
		
        
	}
	padding: 25px 25px;
	.card-body {
		padding: 0;
	}
}
.MorInfo {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: 1px solid #E9F3F3;
	padding: 12px 0;
	p {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #9BA2A1;
		margin-bottom: 0;
	}
	h4 {
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-align: right;
		color: #829291;
		margin-bottom: 0;
	}
}
.CnctWlltMdl.modal-dialog {
	.modal-content {
		background: #FFFFFF;
		box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.05);
		border-radius: 20px;
	}
	.modal-body {
		padding: 0;
	}
	.MdlHdr {
		h5 {
			font-weight: 700;
			font-size: 20px;
			line-height: 27px;
			color: #1A4E4C;
			margin-bottom: 0;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px 25px 16px;
	}	
}
.modal {
    background: rgba(119, 129, 128, 0.4);
    backdrop-filter: blur(10px);
}
.MdlMainCnt {
	padding: 0 24px;
}
button.MdlClosBtn {
	background-color: #DEEDED;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	opacity: 1;
	color: #1A4E4C;
	font-size: 22px;
}
.CnctWllChckBox {
	.form-group {
		display: block;
		margin-bottom: 0;
		input {
			padding: 0;
			height: initial;
			width: initial;
			margin-bottom: 0;
			display: none;
			cursor: pointer;
			&:checked {
				+ {
					label {
						&:after {
							content: '';
							display: block;
							position: absolute;
							top: 5px;
							left: 5px;
							width: 5px;
							height: 10px;
							border: solid #F3FFFF;
							border-width: 0 2px 2px 0;
							transform: rotate(45deg);
						}
					}
				}
			}
		}
		label {
			position: relative;
			font-weight: 500;
			font-size: 14px;
			line-height: 19px;
			color: #829291;
			a {
				color: #258886;
			}
			&:before {
				content: '';
				-webkit-appearance: none;
				padding: 7px;
				display: inline-block;
				position: relative;
				vertical-align: middle;
				cursor: pointer;
				margin-right: 10px;
				background: #258886;
				border-radius: 2px;
			}
		}
	}
}
.WlltDtls {
	a {
		font-weight: 600;
		font-size: 16px;
		line-height: 130%;
		color: #829291;
		background: #E9F3F3;
		border-radius: 85px;
		min-height: 41px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.SerchInpt {
	.form-control {
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		color: #9BA2A1;
		border: 1px solid #E9F3F3;
		border-radius: 27px;
		min-height: 36px;
		padding: 8px 16px;
	}
}
.CmmnlyPairCnt {
	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #829291;
		margin-bottom: 0;
	}
}
.PairdTknBtn {
	border: 1px solid #BEC7C6;
	border-radius: 18px;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: #1A4E4C;
	padding: 4px 8px;
	margin: 8px 16px 0 0px;
	img {
		width: 26px;
		height: 26px;
		object-fit: contain;
		border-radius: 50%;
	}
}
.TknNme {
	h4 {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #1A4E4C;
		margin-bottom: 0;
	}
	p {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #829291;
		margin-bottom: 0;
	}
}
.AllTknsCntLft {
	display: flex;
	align-items: center;
}
.AllTknsCnt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
	&:hover {
		background: #E9F3F3;
		transition: .7s all ease;
	}
}
.FvStr {
	span {
		color: #818B8A;
	}
}
.FvAllTbSec {
	.nav-tabs {
		border-bottom: 1px solid #E9F3F3;
		.nav-item.show {
			.nav-link {
				border-color: transparent transparent #E9F3F3;
				color: #258886;
			}
		}
		.nav-link.active {
			border-color: transparent transparent #E9F3F3;
			color: #258886;
		}
		.nav-link {
			&:focus {
				border-color: transparent transparent #E9F3F3;
				color: #258886;
			}
			&:hover {
				border-color: transparent transparent #E9F3F3;
				color: #258886;
			}
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: #829291;
			padding: 1rem 1.5rem;
		}
	}
}
.MngTknMdlTb {
	padding: 0 24px 24px;
	.nav-tabs {
		background: #FFFFFF;
		box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
		border-radius: 32px;
		justify-content: space-between;
		margin-bottom: 32px;
		border-bottom: unset;
		.nav-item.show {
			.nav-link {
				background: #258886;
				box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
				border-radius: 32px;
				color: #F3FFFF;
				transition: .7s all ease-in-out;
			}
		}
		.nav-link.active {
			background: #258886;
			box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
			border-radius: 32px;
			color: #F3FFFF;
			transition: .7s all ease-in-out;
		}
		.nav-link {
			&:hover {
				background: #258886;
				box-shadow: 0px 4px 30px rgba(3, 41, 41, 0.1);
				border-radius: 32px;
				color: #F3FFFF;
				transition: .7s all ease-in-out;
			}
			background: #E9F3F3;
			border-radius: 32px;
			font-weight: 600;
			font-size: 14px;
			line-height: 19px;
			color: #829291;
			min-width: 193px;
			min-height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .7s all ease-in-out;
			margin: 3px;
		}
	}
}
.MngTkns {
	a {
		font-weight: 600;
		font-size: 18px;
		line-height: 25px;
		color: #258886 !important;
		padding: 29px 0;
		display: block;
	}
}
.AllTknsSec {
	overflow-y: scroll;
    margin-bottom: 0.5rem;
	&::-webkit-scrollbar {
		width: 0px;
	}
	&::-webkit-scrollbar-track {
		background-color: #ebebeb;
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: #6d6d6d;
	}
}
.SlctTknHgt {
	height: 420px;
}
.OnOffSwitch {
	.switch {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 66px;
		height: 32px;
		border-radius: 18px;
		cursor: pointer;
		margin-bottom: 0;
	}
	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		&:checked {
			~ {
				.switch-label {
					background: #ffffff;
					&:before {
						opacity: 0;
					}
					&:after {
						opacity: 1;
					}
				}
				.switch-handle {
					left: 35px;
					background: #21C61E;
					box-shadow: 0px 4px 10px rgba(33, 198, 30, 0.24);
				}
			}
		}
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		text-transform: uppercase;
		-webkit-transition: 0.15s ease-out;
		-moz-transition: 0.15s ease-out;
		-o-transition: 0.15s ease-out;
		transition: 0.15s ease-out;
		-webkit-transition-property: opacity background;
		-moz-transition-property: opacity background;
		-o-transition-property: opacity background;
		transition-property: opacity background;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: #9BA2A1;
		background: #DEEDED;
		border-radius: 16px;
		border: 1px solid #DEEDED;
		&:before {
			position: absolute;
			top: 50%;
			margin-top: -.5em;
			line-height: 1;
			-webkit-transition: inherit;
			-moz-transition: inherit;
			-o-transition: inherit;
			transition: inherit;
			content: attr(data-off);
			right: 9px;
		}
		&:after {
			content: attr(data-on);
			left: 11px;
			opacity: 0;
		}
	}
	.switch-handle {
		position: absolute;
		top: 2px;
		left: 3px;
		width: 28px;
		height: 28px;
		background: white;
		border-radius: 50px;
		-webkit-transition: left 0.15s ease-out;
		-moz-transition: left 0.15s ease-out;
		-o-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
	}
}
.switch-label {
	&:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
	}
}
.MngTknBx {
	background: #FFFFFF;
	border: 1px solid #DFE8E7;
	border-radius: 15px;
	transition: .7s all ease-in-out;
	margin-bottom: 8px;
	&:hover {
		background: #E9F3F3;
		border: 1px solid #E9F3F3;
		transition: .7s all ease-in-out;
	}
	&:focus {
		background: #E9F3F3;
		border: 1px solid #E9F3F3;
		transition: .7s all ease-in-out;
	}
}
.MngTknMdl.modal-dialog {
    max-width: 460px;
} 
.BrdBttm {
	border-bottom: 1px solid #DBDFE0;
}
.TknInptGrp {
	.form-control {
		border: 1px solid #BEC7C6;
		border-radius: 27px;
		min-height: 36px;
		font-weight: 500;
		font-size: 12px;
		color: #1A4E4C;
		line-height: 16px;
	}
	.input-group-text {
		background-color: transparent;
		border: 1px solid #BEC7C6;
		border-radius: 27px;
	}
}
.InptGrpAppnd {
	.input-group-text {
		border-left: unset;
	}
	.form-control {
		border-right: unset;
	}
}
.BtnImprt {
	background: #258886;
	box-shadow: 1px 6px 20px rgba(9, 41, 39, 0.15);
	border-radius: 16px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #F3FFFF;
	min-width: 86px;
	min-height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		color: #fff;
	}
}
.TknImprt {
	background: #FFFFFF;
	box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.05);
	border-radius: 15px;
}
.NoTkns {
	p {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #9BA2A1;
		margin-bottom: 0;
	}
}
.EmtyDiv {
	min-height: 160px;
}
.DsplStSec {
	position: relative;
}
.DsplStSec_show .DsplySett{
    display: block !important;
}
.DsplySett {
	position: absolute;
	top: 45px;
	right: 0;
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-shadow: 2px 1px 50px rgba(0, 0, 0, 0.07);
	border-radius: 20px;
	padding: 25px 30px;
	min-width: 330px;
	z-index: 99;
	.OnOffSwitch {
		.switch {
			background: #DEEDED;
			border-radius: 16px;
		}
	}
	.MdlHdr {
		h5 {
			font-weight: 600;
			font-size: 20px;
			line-height: 27px;
			color: #1A4E4C;
		}
	}
}
.AdvSttSec_show .AdvnSttSec{
    display: block !important;
}
.AdvnSttSec {
	position: absolute;
	top: 0;
	right: 16px;
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-shadow: 2px 1px 50px rgba(0, 0, 0, 0.07);
	border-radius: 20px;
	padding: 25px 30px;
	min-width: 460px;
	min-height: 520px;
	z-index: 99;
}
.TlrncSec {
	h4 {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #829291;
	}
}
.TlrncSecBtn {
	border: 1px solid #DFE8E7;
	border-radius: 32px;
	padding: 9px 12px;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	color: #9BA2A1;
	margin: 5px 10px;
	&:hover {
		background: #DEEDED;
		color: #258886;
	}
}
.TlrncSecBtnSec {
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.GsprcBtns {
	.TlrncSecBtn {
		min-width: 125px;
		margin: 5px 3px;
	}
}
.TimeLmtBtns {
	.TlrncSecBtn {
		min-width: 160px;
	}
}
.EnsuffBlBtn {
	background: #DEEDED;
	border-radius: 27px;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	color: #829291;
	box-shadow: unset;
}
.tool {
	cursor: pointer;
	position: relative;
	text-align: center;
	&::before {
		left: 50%;
		opacity: 0;
		position: absolute;
		z-index: -100;
		border-style: solid;
		border-width: 1em 0.75em 0 0.75em;
		border-color: #FFFFFF transparent transparent transparent;
		box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.05);
		bottom: 80%;
		content: "";
		transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
		transform: scale(.6) translateY(-90%);
		filter: drop-shadow(0px 2px 30px rgba(7, 37, 28, 0.5));
	}
	&::after {
		left: 50%;
		opacity: 0;
		position: absolute;
		z-index: -100;
		background: #FFFFFF;
		box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.5);
		border-radius: 0.25em;
		bottom: 110%;
		color: #1A4E4C;
		content: attr(data-tip);
		padding: 1em;
		transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
		transform: scale(.6) translateX(-50%);
		width: max-content;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		border-radius: 10px;
	}
	&:hover {
		&::before {
			opacity: 1;
			transform: scale(1) translateX(-50%);
			z-index: 100;
			transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
			z-index: 999;
		}
		&::after {
			opacity: 1;
			transform: scale(1) translateX(-50%);
			z-index: 100;
			transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
		}
	}
	&:focus {
		&::before {
			opacity: 1;
			transform: scale(1) translateX(-50%);
			z-index: 100;
			transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
			z-index: 999;
		}
		&::after {
			opacity: 1;
			transform: scale(1) translateX(-50%);
			z-index: 100;
			transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
		}
	}
}
.TllTpW227 {
	&::after {
		content: attr(data-tip);
		width: 227px;
		text-align: initial;
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		color: #829291;
	}
}
.TllTpW195 {
	&::after {
		content: attr(data-tip);
		width: 195px;
	}
}
@media (min-width:992px) and (max-width:1199px) {
	.SwpLqtTitl {
		justify-content: center;
	}
}
@media (max-width:1200px) {
	.BrdRit {
		border-right: unset;
	}
	.UpCmngInHdd {
		p {
			margin-bottom: 15px;
		}
	}
}
@media (max-width:767px) {
	.SwpLqtTitl {
		justify-content: center;
	}
	.tool {
		&:hover {
			&::before {
				transform: scale(1) translateX(0%);
				right: 0;
				left: auto;
			}
			&::after {
				transform: scale(1) translateX(0%);
				right: 0;
				left: auto;
			}
		}
		&:focus {
			&::before {
				transform: scale(1) translateX(0%);
				right: 0;
				left: auto;
			}
			&::after {
				transform: scale(1) translateX(0%);
				right: 0;
				left: auto;
			}
		}
		&::before {
			right: 0;
			left: auto;
			transform: scale(.6) translateX(0%);
		}
		&::after {
			right: 0;
			left: auto;
			transform: scale(.6) translateX(0%);
		}
	}
}
@media (max-width:520px) {
	.AdvnSttSec {
		max-width: 100%;
		min-width: auto;
		left: 15px;
	}
	.TlrncSecBtn {
		margin: 5px 5px;
	}
	.ChrtTknSec {
		margin-bottom: 10px;
	}
	.MngTknMdlTb {
		.nav-tabs {
			justify-content: center;
		}
	}
	.SwpLqtTitl {
		li {
			a {
				min-width: 139px;
			}
		}
	}
}

/* CMS CSS Start */
.cmndvcms{
    background: url('../assets/images/pcyplybg.png') no-repeat;
    background-size: cover;
    .container-cms{
        max-width: 1189px;
        .pcyplydv{
            margin-top: 55px;
            border-bottom: 1px solid #BEC7C6;
            margin-bottom: 120px;
            .pcyplydvtit{
                h3{
                    font-weight: 700;
                    font-size: 44px;
                    line-height: 145%;
                    color: #1A4E4C;
                    text-align: center;
                    margin-bottom: 18px;
                    @media(max-width:767px){
                        font-size:25px;
                    }
                    @media(max-width:575px){
                        font-size:22px
                    }
                }
                p{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 170%;
                    color: #727878;
                    text-align: center;
                    @media(max-width:767px){
                        font-size:15px;
                    }                  
                }
            }
            .pcyplydvapp{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #BEC7C6;
                padding-bottom: 5px;
                margin-bottom: 76px;
                margin-top: 62px;
                span{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: right;
                    color: #258886;
                    @media(max-width:767px){
                        font-size:14px;
                    }
                }
                label{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: right;
                    color: #1A4E4C;
                    margin-left: 25px;
                    margin-bottom: 0;
                    @media(max-width:767px){
                        font-size:14px;
                        margin-left: 16px;
                    }
                }
            }
            .pcyplydvcnt{
                margin-bottom: 62px;
                h5{
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 38px;
                    color: #1A4E4C;
                    margin-bottom: 22px;
                    @media(max-width:767px){
                        font-size:22px;
                        line-height: 27px;
                    }
                }
                h4{
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 38px;
                    color: #1A4E4C;
                    @media(max-width:767px){
                        font-size:22px;
                        line-height: 27px;
                    }
                }
                p{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 170%;
                    text-align: justify;
                    color: #727878;
                    @media(max-width:767px){
                        font-size:15px;
                    }
                    span{
                        color:#258886;
                    }
                }
                ul {
                    margin-top:24px;
                    li{                    
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 27px;
                        color: #455252;
                        position: relative;
                        padding-left: 32px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        @media(max-width:767px){
                            font-size:15px;
                            line-height:20px;
                        }
                        &:before{
                            content:'';
                            position: absolute;
                            background: #0C4241;
                            width:12px;
                            height: 12px;
                            border-radius: 50%;
                            left:0;
                        }
                    }
                }                
            }
        }
        
    }
}
.trdmrk{
    ul li{
        color: #727878 !important;
        font-size: 18px !important;
    }
    h6{
        font-weight: 600;
        font-size: 22px;
        line-height: 150%;
        color: #727878;
        margin-bottom: 2rem;
        @media(max-width:767px){
            font-size:15px;
            line-height:20px;
        }
    }
    h4{
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 27px !important;
        color: #727878 !important;
        @media(max-width:767px){
            font-size:15px;
            line-height:20px;
        }
    }
    .servmrk{
        h4{
            font-weight: 600 !important;
            font-size: 18px !important;
            line-height: 25px !important;
            color: #1A4E4C !important;
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 32px;
            margin-bottom: 1.5rem;
            @media(max-width:767px){
                font-size:15px !important;
                line-height:20px !important;
            }
            &:before{
                content:'';
                position: absolute;
                background: #0C4241;
                width:12px;
                height: 12px;
                border-radius: 50%;
                left:0;
            }         
        }
        span{
            font-weight: 500 !important;
            font-size: 18px !important;
            line-height: 25px !important;
            color: #727878 !important;
            display: block !important;
            margin-bottom: 1rem;
            margin-left: 32px;
            @media(max-width:767px){
                font-size:15px !important;
                line-height:20px !important;
            }
        }
    }
}
.pnf{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: 100vh;
    .inrcnt{
        margin-top: -220px;
        @media(max-width:767px){
            margin-top: -150px;
        }
        h3{
            font-weight: 800;
            font-size: 64px;
            line-height: 87px;
            text-align: center;
            color: #0C4241;
            margin-bottom: 30px;
            @media(max-width:767px){
                font-size: 50px;
            }
        }
        h5{
            font-weight: 700;
            font-size: 40px;
            line-height: 55px;
            text-align: center;
            color: #2C635F;
            margin-bottom: 20px;
            @media(max-width:767px){
                font-size: 25px;
                line-height: 35px;
            }
        }
        p{
            font-weight: 500;
            font-size: 20px;
            line-height: 160%;
            text-align: center;
            color: #455252;
            margin: 0 auto 50px;
            width: 75%;
            display: block;
            @media(max-width:767px){
                width: 100%;
                font-size: 15px;
            }
        }
    }
    .go-back-btn{
        min-width: 126px;
        min-height: 53px;
        background: #0C4241;
        box-shadow: 2px 12px 17px rgba(4, 21, 21, 0.2);
        border-radius: 40px;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #FFFFFF;
        display: block;
        margin: auto;
        &:focus, &:hover{
            background: #258886;
            color: #FFFFFF;
            box-shadow: none;
            transition: all .5s;
        }
    }
}
/* CMS CSS End */


/* Liquadity page css  */

.RdoBtnSec .radio input[type=radio] {
    position: absolute;
    opacity: 0;
}
.RdoBtnSec .radio label{
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #9BA2A1;
    margin-bottom: 0;
}
.RdoBtnSec .radio input[type=radio] + .radio-label:before {
    content: "";
    border-radius: 100%;
    display: inline-block;
    width:18px;
    height:18px;
    position: relative;
    top: -1px;
    margin-right: 0.5em;
    vertical-align:middle;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    background: #FFFFFF;
    border: 2px solid #9BA2A1;
}
.RdoBtnSec .radio input[type=radio]:checked + .radio-label:before {
    background-color: #258886;
    box-shadow: inset 0 0 0 2px #fff;
    border: 2px solid #258886;
}
.RdoBtnSec .radio input[type=radio]:focus + .radio-label:before {
    outline: none;
    border-color:#258886;
}
.RdoBtnSec .radio input[type=radio]:disabled + .radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}
.RdoBtnSec .radio input[type=radio] + .radio-label:empty:before {
    margin-right: 0;
}
.RdoBtnSec .radio input[type=radio]:checked + .radio-label{
   
    color: #1A4E4C;
}
.LqHddSec{
    display: flex;
    justify-content: space-between;
}
.LqBrdBttm{
    border-bottom: 1px solid #E9F3F3;
}
.GrdLstSec li a:hover img{
    filter: brightness(1.4);
    transition: .8s all ease-in-out;
}
.LqpoolHdd{
    padding:25px 20px;
}
.LiqdtySec{
    border: 1px solid #258886;
    filter: drop-shadow(2px 1px 50px rgba(0, 0, 0, 0.07));
    border-radius: 20px;
}
.LqSrchSec .form-control{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1A4E4C;
    border: 1px solid #DEEDED;
    border-radius: 19px;
    min-height:34px;
    box-shadow:unset;
}
.LqSrchSec .form-control::placeholder{
    color: #9BA2A1;
}
.LftSrchIcon .form-control{
    padding-left: 40px;
    background: url(../assets/images/srch-bg-icon.svg)no-repeat left 12px center;
}
.LiveFinshSec .nav-tabs{
    border: 1px solid #258886;
    border-radius: 19px;
    padding: 0px 1px 1px;
    display: inline-flex;
}
.LiveFinshSec .nav-tabs .nav-item.show .nav-link, 
.LiveFinshSec .nav-tabs .nav-link.active,
.LiveFinshSec .nav-tabs .nav-link:hover{
    background: #258886;
    border-radius: 28px;
    color:#fff;
}
.LiveFinshSec .nav-tabs .nav-link{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #9BA2A1;
    padding: 6px 19px;
}
.SlctTknBtnSec .custom-select{
    background:url(../assets/images/slct-tkn-arrw.png)no-repeat right 10px center;
    border: 1px solid #258886;
    border-radius: 19px;
    box-shadow:unset;
    min-height:34px;
    padding: 6px 40px 6px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #258886;
}
.SlctTknBtnSec .custom-select option{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #9BA2A1;
}
.SlctTknBtnSec .custom-select option:hover{
    background: #DEEDED;
    border-radius: 4px;
    padding:8px 14px 6px 8px;

}
.PoolsHddSec{
    display: flex;
    justify-content: space-between;
}
.PoolsHddCnt h4{
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #1A4E4C;
}
.PoolsHddCnt h6{
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #727878; 
}
.PoolsHddSec{
    background: #FFFFFF url(../assets/images/pools-hddsec-bg.png)no-repeat center;
    box-shadow: 0px 4px 39px rgba(171, 156, 208, 0.25);
    background-size:cover;
    padding:15px 20px;
    border-radius:20px 20px 0 0;
    margin-bottom:40px;
}
.PoolsCrdItm{
    background: #FFFFFF;
    border-radius: 20px;
}
.PoolsDtlsCnt h4{
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #258886;
}
.PoolsDtlsCnt h6{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #727878;
}
.PoolsDtlsCnt{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:14px 20px;
}
.Mnht-42{
    min-height:42px;
}
.AddLiqt{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #F3FFFF;
}
.PoolsHddImgs{
    position: relative;
    width: 35px;
}
.PolsTknImg{
    position:absolute;
}
.PolsTknImg.PolsTknImg1 {
    top: 21px;
    left: -18px;
    z-index: 1;
}
.PolsTknImg img{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.CnctWlltBtnSec {
    padding: 30px 15px 30px;
}
.LiqCrdSecHgt{
    height:1145px;
    margin-right: 7px;
}
.PrvdLiqSec .SellSec{
    border: 1px solid #E9F3F3;
    border-radius: 10px;
}
.TknVlue .form-control{
    border:unset;
    box-shadow:unset;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #727878;
    width:140px;
    padding-left: 0;
}
.PrvdLiqSec .SlctTknBtn{
    padding:3px 12px 4px 12px;
}
.PrvdLiqSec .ExchngeBtnSec{
    position: absolute;
    left: 50%;
    right: auto;
    top: -12px;
    transform: translateX(-50%);
}
.PoolShareSec{
    padding:30px 25px;
}
.PolShrHdd h4{
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #1A4E4C;
}
.NoDataCnt p{
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #829291;
}
.NoDataCnt {
    display: flex;
    justify-content: center;
    align-items: center;
}
.MnHght-135{
    min-height: 135px;
}
.PolShrCnt h4{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #258886;
}
.PolShrCnt h6{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom:2px;
}
.PolShrCntSec{
    display:flex;
    align-items: center;
}
.PolShrDtls{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.PolShrCnt{
    min-width:95px;
}
.PoolsTrnsTbl .table td{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #727878;
}
.PoolsTrnsTbl .table thead th{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #9BA2A1;
    border-bottom:1px solid #E9F3F3;
}
.PlTrnsSec{
    background: #F3FFFF;
    border-radius: 20px;
}
.BrdRdsTop{
    border-radius:20px 20px 0 0;
}
.TknDropSdw img{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.PoolsTrnsTbl .table thead th,
.PoolsTrnsTbl .table td{
    padding:0.75rem 20px;
    white-space:nowrap;
}
.SwpFlx{
    flex-wrap: wrap;
}
.MnHght-298{
    height:298px;
}
.NoActivsCnt h4{
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: #1A4E4C;
}
.NoActivsCnt p{
    font-weight: 500;
    font-size: 16px;
    line-height: 170%;
    color: #727878;
}
.NoActivTop{
    padding-top: 6rem;
}
.CusScrll.AllTknsSec::-webkit-scrollbar-thumb{
    background: #BEC7C6;
    border-radius: 2px;
}
.CusScrll.AllTknsSec::-webkit-scrollbar{
    width:8px;
}
@media (max-width:767px){
    .RdoBtnSec .radio label{
        font-size: 14px;
    }
    .SlctTknBtn{
        font-size: 14px;
    }
}
.PoolsLstCnt h4{
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #258886;
    margin-bottom:2px;
}
.PoolsLstCnt h6{
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #727878;
    margin-bottom:2px;
}
.PoolsLstCnt span{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #727878;
}
.VlTvFeApCnt{
    background: #FFFFFF;
    box-shadow: 0px 2px 30px rgba(7, 37, 28, 0.05);
    border-radius: 8px;
    padding: 10px 12px 5px 8px;
    min-width:108px;
    margin-left: 8px;
    margin-bottom:10px;
}
.VlTvFeApSec {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.VlTvFeApCnt h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #727878;
}
.VlTvFeApCnt h4{
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #258886;
}
.CpyCnt span{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #258886;
}
.CpyViewSec{
    display:flex;
}
.LstVwPolBtn{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.PoolsLstBox{
    background: #FFFFFF;
    box-shadow: 0px 4px 39px rgba(171, 156, 208, 0.25);
    border-radius: 20px;
    padding:24px 24px;
}
.LstVwHddImg .PolsTknImg.PolsTknImg1{
    right:-20px;
    top: 26px;
    left: auto;
}
.LstVwHddImg .PolsTknImg img{
    transform: scale(1.19);
}
.PoolsLstItm{
    background: #F3FFFF;
    border-radius: 20px;
    min-height: 210px;
    @media(max-width:992px){
        min-height: 280px;
    }
    @media(max-width:835px){
        min-height: 360px;
    }
    @media(max-width:768px){
        min-height: 370px;
    }
    @media(max-width:575px){
        min-height: 485px;
    }
    @media(max-width:450px){
        min-height: 510px;
    }
}
.LstCllpse .card{
    background: transparent;
}
.ChrtHddSec{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.LwChrtSec{
    border: 1px solid #DEEDED;
    border-radius: 20px;
    padding:18px 17px 20px 19px;
}
.ChrtTabs.LiveFinshSec .nav-tabs .nav-link{
    padding:6px 14px;
    font-size: 12px;
    color: #727878;
}
.ChrtTabs.LiveFinshSec .nav-tabs .nav-item.show .nav-link, 
.ChrtTabs.LiveFinshSec .nav-tabs .nav-link.active, 
.ChrtTabs.LiveFinshSec .nav-tabs .nav-link:hover{
    color:#fff;
}
.ChrtTabs.LiveFinshSec .nav-tabs{
    background: #F3FFFF;
    border: 1px solid #DEEDED;
    border-radius: 17px;
}
.LwArrwSlct.SlctTknBtnSec .custom-select{
    border: 1px solid #DEEDED;
}
.ChrtFees h4{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #258886;
    margin-bottom:0;

}
.ChrtFees h5{
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #727878;

}
.LiqLstChrtSec{
    padding:40px 24px 24px;
}
.LwPlsDtlsCnt h5{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #829291;
   
}
.LwPlsDtlsCnt h4{
    color: #258886;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.02em;
}
.LwPlsDtlsCnt{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom:15px;
}
.LstPoolsDtlsHdd h4{
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #1A4E4C;
}
.LstPoolsDtls{
    padding:16px 16px;
    background: #F3FFFF;
    border: 1px solid #DEEDED;
    border-radius: 20px;
}
.LwArrwSlct.SlctTknBtnSec .custom-select {
    background: url(../assets/images/slct-dwn-arrow.svg)no-repeat right 10px center;
}
.LstCntDot{
    background: #1A4E4C;
    width:5px;
    height:5px;
    border-radius: 10px;
    display: inline-block;
    margin:0 10px;
}
.Mnht-36{
    min-height:36px;
}

@media (max-width:991px){
    .PolsTknImg{
        position:initial;
    }
    .PoolsHddImgs{
        margin-bottom:10px;
    }
}
@media (max-width:767px){
    .RdoBtnSec .radio label{
        font-size: 14px;
    }
    .SlctTknBtn{
        font-size: 14px;
    }
    .LstVwPolBtn{
        margin-bottom: 10px;
    }
    .PoolsLstCnt{
        margin-bottom: 10px;
    }
    .LwChrtSec,.CpyViewSec{
        margin-bottom:10px;
    }
    .ChrtTabs.LiveFinshSec{
        flex-wrap: wrap;
        margin-bottom:10px;
    }
    .ChrtTabs.LiveFinshSec .nav-tabs{
        margin-bottom:10px;
    }
    
}

.CllpseSec .collapse.show ~ .card-head img{
    transform: rotate(180deg);
}

.PoolsLstItm .CllpseSec span.Collapsible__trigger::after{
    left: 50%;
    top:15px;
}
// .LiqCrdSec.lsdvwdv{
//     .grdvw{
//         display: none;
//     }
//     .lsdvw{
//         display: block !important;
//     }
// }

.marquee-container{
    overflow:hidden;
}